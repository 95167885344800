import {useContext} from 'react'
import {Menu} from "../catalog/Menu";
import {AppContext} from "../../store/context";

export const Button = ({count, icon, text, className, onClick}) => {
    return <div onClick={() => {
        onClick()
    }} className={`btn ${className || ''}`}>
        {icon ? <div className="btn__icon"><img src={icon} alt=""/></div> : null}
        {text ? <div className="btn__text">{text}</div> : null}
    </div>
}

export const CartButton = ({count, icon, text, className, onClick}) => {
    return <div onClick={() => {
        onClick()
    }} className={`btn btn-cart ${className || ''}`}>
        {count && count > 0 ? <div className="btn__label-top">{count}</div> : null}
        {icon ? <div className="btn__icon"><img src={icon} alt=""/></div> : null}
        {text ? <div className="btn__text">{text}</div> : null}
    </div>
}

export const CatalogButton = ({icon, text, type, className, onClick}) => {
    const {context} = useContext(AppContext);

    const open = () => {
        context.modal.dispatch({type: 'openModal', payload: {type: type}})
    }

    const close = () => {
        context.modal.dispatch({type: 'closeModal'})
    }

    const isOpen = () => {
        return context.modal.state.open && context.modal.state.type === type
    };

    return <div className="catalog-button">
        <div onClick={() => {
            !isOpen() ? open() : close()
        }} className={`btn btn-catalog ${className || ''}`}>
            {text ? <div className="btn__text">{text}</div> : null}
            {icon ? <div className="btn__icon"><img src={icon} alt=""/></div> : null}
        </div>
        <div className={`catalog-button--menu ${isOpen() ? 'active' : ''}`}>
            <Menu/>
        </div>
    </div>

}


export const MenuButton = ({className, onClick}) => {
    return <div onClick={() => {
        onClick()
    }} className={`btn btn-menu ${className || ''}`}>
       <div className="hamburger">
           <div className="hamburger__icon"/>
       </div>
    </div>
}