import React from 'react'
import {Link} from 'react-router-dom';

import {AppContext} from "../store/context";
import {LoaderFullsize} from "../components/Loader";
import {Title} from "../components/ui/Title";
import {STATIC_HOST} from "../const";
import {CartClearIcon} from "../components/ui/Icons";
import {Button} from "../components/ui/Button";

import {pluralize} from '../components/Helpers'

import nofoto from '../static/img/photo-placeholder.png'
import {Counter} from "../components/ui/Counter";

export class CartPage extends React.Component {
    constructor() {
        super();

        this.plusProduct = this.plusProduct.bind(this);
        this.minusProduct = this.minusProduct.bind(this);

        this.state = {}
    }

    reloadCart() {
        this.context.context.processor.cart.cart().then(res => {
            this.context.context.cart.dispatch({type: 'setCart', payload: res.cart});
        }).then(() => {
            this.componentDidMount()
        });
    }

    plusProduct(id) {
        this.context.context.processor.cart.addProduct(id).then(res => {
            const products = Object.values(res.cart.products)

            this.context.context.cart.dispatch({type: 'setCart', payload: res.cart});

            this.setState({...this.state, cart: {...this.state.cart, products: products}})
        }).then(() => {
            this.recalculateCart()
        });
    }

    minusProduct(id) {
        this.context.context.processor.cart.removeProduct(id).then(res => {
            const products = Object.values(res.cart.products)

            this.context.context.cart.dispatch({type: 'setCart', payload: res.cart});

            this.setState({...this.state, cart: {...this.state.cart, products: products}})
        }).then(() => {
            this.recalculateCart()
        });
    }

    clearProduct(id) {
        this.context.context.processor.cart.clearProduct(id).then(res => {
            const products = Object.values(res.cart.products)

            this.context.context.cart.dispatch({type: 'setCart', payload: res.cart});

            this.setState({...this.state, cart: {...this.state.cart, products: products}})
        }).then(() => {
            this.recalculateCart()
        });
    }

    recalculateCart(shop) {
        const shops = this.state.cart.offers;
        const products = this.state.cart.products;

        const shopsWithCount = shops.map(shop => {
            let count = 0;

            products.map(p => {
                if (shop.balances.find(b => {
                    return b.id === p.id && b.balance >= p.quantity
                })) {
                    count = count + 1
                }
            });

            return {...shop, count: count};
        });

        const availableShops = shopsWithCount.filter(shop => {
            return shop.count !== 0;
        });

        const unavailableShops = shopsWithCount.filter(shop => {
            return shop.count === 0;
        });

        const selected = shop || availableShops.find(shop => {
            return shop.count === products.length
        }) || shopsWithCount[0];

        availableShops.sort((a, b) => {
            if (a.count > b.count) {
                return -1;
            }
            if (a.count < b.count) {
                return 1;
            }

            return 0;
        });

        const availableProducts = products.filter(p => {
            return !!selected.balances.find(b => {
                return b.id === p.id && b.balance >= p.quantity
            })
        });

        const unavailableProducts = products.filter(ap => {
            return !availableProducts.find(p => {
                return p.id === ap.id;
            })
        });

        this.setState({
            ...this.state,
            products: {
                available: availableProducts,
                unavailable: unavailableProducts,
            },
            shops: {
                available: availableShops,
                unavailable: unavailableShops,
            },
            selected: selected,
        })
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        this.context.context.processor.cart.cartExtended().then(res => {
            const products = Object.values(res.cart.products)

            this.setState({...this.state, cart: {products: products, offers: res.offers}})
        }).then(() => {
            this.recalculateCart()
        }).then(() => {
            this.setState({...this.state, loaded: true})
        });
    }

    productPrice(id) {
        const shop = this.state.selected;

        if (!shop) {
            return 0;
        }

        const p = shop.balances.find(el => {
            return el.id === id
        });

        return p ? p.price : 0
    }

    sumPrice() {
        const prices = this.state.products.available.map(el => {
            return this.productPrice(el.id) * el.quantity
        });

        return prices.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    }

    availableLabel(count) {
        if (count === 0) {
            return 'Нет в наличии'
        }

        if (count === this.state.cart.products.length) {
            return 'В наличии все позиции'
        }

        return `В наличии ${pluralize(count, ['позиция', 'позиции', 'позиций'])} из ${this.state.cart.products.length}`;
    }

    select(el) {
        this.recalculateCart(el)
    }

    render() {
        return this.state.loaded ? <div className="cart-wrapper">
            <div className="cart-container container">
                <div className="cart-title">
                    <Title title={"Корзина"}/>
                </div>
                {this.state.cart.products.length ?
                    <div className="cart-layout">
                        <div className="cart-products">
                            <div className="cart-products--active">
                                {this.state.products.available.map(el => {
                                    return <div className="cart-product">
                                        <div className="cart-product__image">
                                            <img src={el.image ? STATIC_HOST + el.image : nofoto} alt=""/>
                                        </div>
                                        <div className="cart-product__title">
                                            <Link to={`/product/${el.slug}`}>{el.title}</Link>
                                        </div>
                                        <div className="cart-product__counter">
                                            <Counter product={el} plusCallback={this.plusProduct}
                                                     minusCallback={this.minusProduct}/>
                                        </div>
                                        <div className="cart-product__price">
                                            {this.productPrice(el.id) * el.quantity} ₽
                                        </div>
                                        <div className="cart-product__clear" onClick={() => {
                                            this.clearProduct(el.id)
                                        }}>
                                            <CartClearIcon/>
                                        </div>
                                    </div>
                                })}
                            </div>
                            {this.state.products.unavailable.length > 0 ?
                                <>
                                    <div className="cart-products__unavailable">
                                        <strong>Отсутствующие товары</strong> ({this.state.selected.address})
                                    </div>
                                    <div className="cart-products--inactive">
                                        {this.state.products.unavailable.map(el => {
                                            return <div className="cart-product">
                                                <div className="cart-product__image">
                                                    <img src={el.image ? STATIC_HOST + el.image : nofoto} alt=""/>
                                                </div>
                                                <div className="cart-product__title">
                                                    <Link to={`/product/${el.slug}`}>{el.title}</Link>
                                                    <span>Отсутствует необходимое количество товара в этом магазине</span>
                                                </div>
                                                <div className="cart-product__counter">
                                                    <Counter product={el} plusCallback={this.plusProduct}
                                                             minusCallback={this.minusProduct}/>
                                                </div>
                                                <div className="cart-product__price">
                                                    {this.productPrice(el.id) * el.quantity} ₽
                                                </div>
                                                <div className="cart-product__clear" onClick={() => {
                                                    this.clearProduct(el.id)
                                                }}>
                                                    <CartClearIcon/>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </> : null
                            }
                        </div>
                        <div className="cart-shops">
                            <div className="cart-shops__heading">
                                <div className="cart-shops__heading-title">
                                    Выберите магазин оформления заказа
                                </div>
                                <div className="cart-shops__heading-subtitle">
                                    Вы можете забрать заказ самостоятельно или оформить доставку из магазина
                                </div>
                            </div>
                            <div className="cart-shops__body">
                                {this.state.shops.available.map(el => {
                                    return <div className="cart-shop" onClick={() => {
                                        this.select(el)
                                    }}>
                                        <input type="radio" className="cart-shop__checkbox"
                                               checked={this.state.selected.id === el.id}/>
                                        <div className="cart-shop__title">
                                            <div className="cart-shop__title--address">{el.address}</div>
                                            <div
                                                className="cart-shop__title--availability available">{this.availableLabel(el.count)}</div>
                                        </div>
                                    </div>
                                })}
                                {this.state.shops.unavailable.map(el => {
                                    return <div className="cart-shop cart-shop--inactive">
                                        <input type="radio" className="cart-shop__checkbox" disabled={true}/>
                                        <div className="cart-shop__title">
                                            <div className="cart-shop__title--address">{el.address}</div>
                                            <div
                                                className="cart-shop__title--availability">{this.availableLabel(el.count)}</div>
                                        </div>
                                    </div>
                                })}
                            </div>
                            <div className="cart-shops__footer">
                                <div className="cart-shops__sum">
                                    <div className="cart-shops__sum-products">
                                        <span>Итого</span>
                                        <strong>&nbsp;{pluralize(this.state.products.available.length, ['товар', 'товара', 'товаров'])}&nbsp;</strong>
                                        <span>на сумму:</span>
                                    </div>
                                    <div className="cart-shops__sum-price">
                                        {this.sumPrice()} ₽
                                    </div>
                                </div>
                                <div className="cart-shops__button">
                                    {this.state.products.available.length > 0 ?
                                        <Button className={"btn-green--filled"} onClick={() => {
                                            this.context.context.modal.dispatch({
                                                type: 'openModal',
                                                payload: {
                                                    type: 'checkout',
                                                    params: {
                                                        shop: this.state.selected.id,
                                                        products: this.state.products.available.map(el => {
                                                            return el.id
                                                        }),
                                                        success: () => {
                                                            this.reloadCart()
                                                        }
                                                    }
                                                }
                                            })
                                        }} text={"Перейти к оформлению"}/> : null}
                                </div>
                            </div>
                        </div>
                    </div> : <div className="cart-layout">
                        Корзина пуста
                    </div>}

            </div>
        </div> : <LoaderFullsize/>
    }
}

CartPage.contextType = AppContext;