import {useContext, useEffect, useState} from "react";
import {Link, useHistory} from 'react-router-dom'


import {AppContext} from "../store/context";
import {SidebarMenu} from "./catalog/Menu";
import {Button} from "./ui/Button";

import logo from '../static/img/logo-footer.svg'
import instagram from '../static/img/icons/socials/instagram.svg'
import whatsapp from '../static/img/icons/socials/whatsapp.svg'
import odnoklassniki from '../static/img/icons/socials/odnoklassniki.svg'
import vk from '../static/img/icons/socials/vk.svg'
import facebook from '../static/img/icons/socials/facebook.svg'
import youtube from '../static/img/icons/socials/youtube.svg'
import feedback from '../static/img/icons/feedback.svg'
import caret from '../static/img/icons/caret.svg'
import close from '../static/img/icons/close.svg'
import profile from '../static/img/icons/profile.svg'
import search from "../static/img/icons/search.svg";

export const Sidebar = () => {
    const {context} = useContext(AppContext);
    const history = useHistory();

    const [open, setOpen] = useState(false);
    const [display, setDisplay] = useState(false);

    const isOpen = context.modal.state.open && context.modal.state.type === 'sidebar';

    useEffect(() => {
        if (isOpen === true) {
            setDisplay(isOpen);
            setTimeout(() => {
                setOpen(isOpen)
            }, 10)
        } else {
            setOpen(isOpen);
            setTimeout(() => {
                setDisplay(isOpen)
            }, 300)
        }
    }, [isOpen]);


    const personalButtonAction = () => {
        if (!context.user.state.customer.phone) {
            context.modal.dispatch({type: 'openModal', payload: {type: 'auth'}})
            return;
        }

        context.modal.dispatch({type: 'closeModal'})
        history.push('/personal')
    };

    const submitSearch = (e) => {
        e.preventDefault();

        const s = e.target.query.value

        context.modal.dispatch({type: 'closeModal'})

        history.push(`/catalog/search?query=${s}`);
    }

    return <div  className={`sidebar ${open ? 'open' : ''}`} style={{display: display ? 'block' : 'none'}}>
        <div className="sidebar-container">
            <div className="sidebar-top">
                <div className="sidebar-close">
                    <Button icon={close} className={'btn-transparent'} onClick={() => {context.modal.dispatch({type: 'closeModal'})}}/>
                </div>
                <div className="sidebar-city">
                    <SidebarCity/>
                </div>
                <div className="sidebar-personal">
                    <Button onClick={() => {
                        personalButtonAction()
                    }} className={'btn-green--outline'} icon={profile}/>
                </div>
            </div>
            <form className="sidebar-search" action="/catalog/search" onSubmit={submitSearch}>
                <span className="header__search-warning">Введите минимум 3&nbsp;символа</span>
                <input type="text" name="query" placeholder="Поиск товаров"
                       className="header__search-input"/>
                <button type="submit" className="header__search-button"><img src={search} alt=""/></button>
            </form>
            <SidebarMenu/>
            <div className="sidebar-menu">
                <div className="sidebar-menu__primary">
                    <ul className="footer__menu footer__menu--primary">
                        <li className="footer__menu-item">
                            <Link onClick={() => {context.modal.dispatch({type: 'closeModal'})}} to={"/7reasons"}>7 причин купить у нас</Link>
                        </li>
                        <li className="footer__menu-item">
                            <Link onClick={() => {context.modal.dispatch({type: 'closeModal'})}} to={"/contacts"}>Точки продаж</Link>
                        </li>
                        <li className="footer__menu-item">
                            <Link onClick={() => {context.modal.dispatch({type: 'closeModal'})}} to={"/loyalty"}>Программа лояльности</Link>
                        </li>
                        <li className="footer__menu-item">
                            <Link onClick={() => {context.modal.dispatch({type: 'closeModal'})}} to={"/chasto-zadavaemye-voprosy-otvety"}>Часто задаваемые вопросы-ответы</Link>
                        </li>
                        <li className="footer__menu-item">
                            <Link onClick={() => {context.modal.dispatch({type: 'closeModal'})}} to={"/news"}>Новости</Link>
                        </li>
                    </ul>
                </div>
                <div className="sidebar-menu__secondary">
                    <ul className="footer__menu footer__menu--secondary">
                        <li className="footer__menu-item">
                            <Link onClick={() => {context.modal.dispatch({type: 'closeModal'})}} to={"/rekvizity"}>Реквизиты</Link>
                        </li>
                        <li className="footer__menu-item">
                            <Link onClick={() => {context.modal.dispatch({type: 'closeModal'})}} to={"/company"}>О компании</Link>
                        </li>
                        <li className="footer__menu-item">
                            <Link onClick={() => {context.modal.dispatch({type: 'closeModal'})}} to={"/checkout"}>Как оформить заказ</Link>
                        </li>
                        <li className="footer__menu-item">
                            <Link onClick={() => {context.modal.dispatch({type: 'closeModal'})}} to={"/oplata-i-dostavka"}>Оплата и доставка</Link>
                        </li>
                        <li className="footer__menu-item">
                            <Link onClick={() => {context.modal.dispatch({type: 'closeModal'})}} to={"/warranty-and-return"}>Гарантия и возврат</Link>
                        </li>
                        <li className="footer__menu-item">
                            <Link onClick={() => {context.modal.dispatch({type: 'closeModal'})}} to={"/eula"}>Пользовательское соглашение</Link>
                        </li>
                        <li className="footer__menu-item">
                            <Link onClick={() => {context.modal.dispatch({type: 'closeModal'})}} to={"/privacy"}>Политика конфиденциальности</Link>
                        </li>
                        <li className="footer__menu-item">
                            <Link onClick={() => {context.modal.dispatch({type: 'closeModal'})}} to={"/contacts"}>Контакты</Link>
                        </li>
                        <li className="footer__menu-item">
                            <Link onClick={() => {context.modal.dispatch({type: 'closeModal'})}} to={"/dlya-postavshhikov"}>Для поставщиков</Link>
                        </li>
                        <li className="footer__menu-item">
                            <Link onClick={() => {context.modal.dispatch({type: 'closeModal'})}} to={"/news"}>Новости</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="sidebar-footer">
                <div className="sidebar-footer__icons">
                    <ul className="sidebar-footer__social-list">
                        <li className="sidebar-footer__social-item">
                            <a href="https://www.instagram.com/universadshop/"
                               title="Instagram" target="_blank">
                                <img src={instagram} alt="Instagram"/>
                            </a>
                        </li>
                        <li className="sidebar-footer__social-item">
                            <a href="https://api.whatsapp.com/send?phone=79841995050&amp;text=" title="whats app"
                               target="_blank">
                                <img src={whatsapp} alt="whats app"/>
                            </a>
                        </li>
                        <li className="sidebar-footer__social-item">
                            <a href="https://ok.ru/universad" title="Одноклассники" target="_blank">
                                <img src={odnoklassniki} alt="Одноклассники"/>
                            </a>
                        </li>
                        <li className="sidebar-footer__social-item">
                            <a href="https://vk.com/universad1" title="VK" target="_blank">
                                <img src={vk} alt="VK"/>
                            </a>
                        </li>
                        <li className="sidebar-footer__social-item">
                            <a href="https://www.facebook.com/groups/228937050774861/?multi_permalinks=1395992930735928"
                               title="facebook" target="_blank">
                                <img src={facebook} alt="facebook"/>
                            </a>
                        </li>
                        <li className="sidebar-footer__social-item">
                            <a href="https://www.youtube.com/channel/UCM7XQhbs30IzfGOryj2RgdA" title="Youtube"
                               target="_blank">
                                <img src={youtube} alt="Youtube"/>
                            </a>
                        </li>
                    </ul>
                </div>
                <Button className={'btn-white'} text={"Подписаться на рассылку"}/>
                <Button className={'btn-orange--outline'} icon={feedback} text={"Помогите нам стать лучше"}/>
            </div>
            <div className="sidebar-copyright">
                <div className="sidebar-copyright__logo">
                    <img src={logo} alt=""/>
                </div>
                <div className="sidebar-copyright__text">
                    <span>© ИП {context.user.state.legal || 'ИП Прожерин Иван Анатольевич'}, 2022</span>
                </div>
            </div>
        </div>
    </div>
}

const SidebarCity = () => {
    const {context} = useContext(AppContext);
    const [open, setOpen] = useState(false)


    const selectCity = (id) => {
        context.user.dispatch({type: 'dontAsk'});

        context.processor.user.selectCity(id).then(() => {
            window.location.reload()
        })
    };

    const toggleSelector = () => {
        setOpen(!open)
    };

    return <div className="sidebar-city__city">
        <div className="sidebar-city__city-value" onClick={() => {
            toggleSelector()
        }}>
            <span className={"sidebar-city__city-value--text black stroke"}>{context.user.state.city.title}</span>
            <span className={"sidebar-city__city-value--icon"}><img src={caret} alt=""/></span>
        </div>
        <div className={`sidebar-city__city-value--selector ${open ? 'active' : ''}`}>
            <div className="sidebar-city__city-value--selector-box">
                <ul className="sidebar-city__city-value--selector-list">
                    {context.user.state.cities.map(el => {
                        return <li className="sidebar-city__city-value--selector-item" onClick={() => {
                            selectCity(el.id)
                        }}>{el.title}</li>
                    })}
                </ul>
            </div>
        </div>
    </div>
}