import {Base} from "./base.api";
import {API_HOST} from "../const";

const BASE_URL = `${API_HOST}/pages`;

export class Pages extends Base {
    page(slug) {
        return this.fetchJSON(`${BASE_URL}/?slug=${slug}`).then(response => {
            return response.data
        })
    }
}