export const Counter = ({product, plusCallback, minusCallback}) => {
    return <div className="product__counter--wrapper">
        <div className="product__counter--minus" onClick={() => {
            minusCallback(product.id)
        }}>-
        </div>
        <div className="product__counter--count">{product.quantity}</div>
        <div className="product__counter--plus" onClick={() => {
            plusCallback(product.id)
        }}>+
        </div>
    </div>
}