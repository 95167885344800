import React, {createContext, useEffect, useReducer, useState} from "react";
import {Processor} from "../api/processor";
import {toast} from 'react-toastify'
import {CatalogInitialState, CatalogReducer, UserInitialState, UserReducer, ModalInitialState, ModalReducer, CartReducer, CartInitialState} from './reducers'


export const AppContext = createContext();
export const AppContextValue = () => {
    const [catalogState, catalogDispatch] = useReducer(CatalogReducer, CatalogInitialState);
    const [userState, userDispatch] = useReducer(UserReducer, UserInitialState);
    const [cartState, cartDispatch] = useReducer(CartReducer, CartInitialState);
    const [modalState, modalDispatch] = useReducer(ModalReducer, ModalInitialState);


    const isMobile = useCheckMobileScreen();

    return {
        settings: {
            isMobile: isMobile
        },
        user: {
            state: userState,
            dispatch: userDispatch
        },
        catalog: {
            state: catalogState,
            dispatch: catalogDispatch
        },
        cart: {
            state: cartState,
            dispatch: cartDispatch,
        },
        modal: {
            state: modalState,
            dispatch: modalDispatch
        },
        toast: toast,
        processor: Processor
    }
};

const useCheckMobileScreen = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (width <= 640);
};
