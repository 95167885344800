import {useContext} from 'react'
import {Link} from 'react-router-dom'
import {AppContext} from "../store/context";

import {Button} from "./ui/Button";

import logo from '../static/img/logo-footer.svg'
import whatsapp from '../static/img/icons/socials/whatsapp.svg'
import odnoklassniki from '../static/img/icons/socials/odnoklassniki.svg'
import telegram from '../static/img/icons/socials/telegram.svg'
import vk from '../static/img/icons/socials/vk.svg'
import youtube from '../static/img/icons/socials/youtube.svg'
import feedback from '../static/img/icons/feedback.svg'


export const Footer = () => {
    return <FooterDesktop/>
}

const FooterDesktop = () => {
    const {context} = useContext(AppContext);

    return <>
        <div className="footer">
            <div className="footer__container container">
                <div className="footer__grid">
                    <div className="footer__column footer__column--first">
                        <div className="footer__logo">
                            <Link to={"/"}>
                                <img src={logo} alt="Универсад"/>
                            </Link>
                        </div>
                        <div className="footer__contacts">
                            <div className="footer__contacts-item footer__contacts-item--phone">
                                <a href="tel:8 (800) 250-08-99">8 (800) 250-08-99</a>
                            </div>
                            <div className="footer__contacts-item footer__contacts-item--email">
                                <a href="mailto:online@universad.ru">online@universad.ru</a>
                            </div>
                        </div>
                        <div className="footer__social">
                            <ul className="footer__social-list">
                                <li className="footer__social-item">
                                    <a href="https://api.whatsapp.com/send?phone=79841995050&amp;text="
                                       title="whats app"
                                       target="_blank">
                                        <img src={whatsapp} alt="whats app"/>
                                    </a>
                                </li>
                                <li className="footer__social-item">
                                    <a href="https://ok.ru/universad" title="Одноклассники" target="_blank">
                                        <img src={odnoklassniki} alt="Одноклассники"/>
                                    </a>
                                </li>
                                <li className="footer__social-item">
                                    <a href="https://vk.com/universad1" title="VK" target="_blank">
                                        <img src={vk} alt="VK"/>
                                    </a>
                                </li>
                                <li className="footer__social-item">
                                    <a href="https://www.youtube.com/channel/UCM7XQhbs30IzfGOryj2RgdA" title="Youtube"
                                       target="_blank">
                                        <img src={youtube} alt="Youtube"/>
                                    </a>
                                </li>
                                <li className="footer__social-item">
                                    <a href="http://t.me/universadshop" title="Telegram"
                                       target="_blank">
                                        <img src={telegram} alt="Telegram"/>
                                    </a>
                                </li>

                            </ul>
                        </div>
                        <div className="footer__menu--duplicate">
                            <ul className="footer__menu footer__menu--primary">
                                <li className="footer__menu-item">
                                    <Link to={"/7reasons"}>7 причин купить у нас</Link>
                                </li>
                                <li className="footer__menu-item">
                                    <Link to={"/contacts"}>Точки продаж</Link>
                                </li>
                                <li className="footer__menu-item">
                                    <Link to={"/loyalty"}>Программа лояльности</Link>
                                </li>
                                <li className="footer__menu-item">
                                    <Link to={"/chasto-zadavaemye-voprosy-otvety"}>Часто задаваемые
                                        вопросы-ответы</Link>
                                </li>
                                <li className="footer__menu-item">
                                    <Link to={"/news"}>Новости</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="footer__copyright">
                            <span>© {context.user.state.legal || 'ИП Прожерин Иван Анатольевич'}, 2022</span>
                        </div>
                    </div>
                    <div className="footer__column footer__column--second">
                        <ul className="footer__menu footer__menu--primary">
                            <li className="footer__menu-item">
                                <Link to={"/7reasons"}>7 причин купить у нас</Link>
                            </li>
                            <li className="footer__menu-item">
                                <Link to={"/contacts"}>Точки продаж</Link>
                            </li>
                            <li className="footer__menu-item">
                                <Link to={"/loyalty"}>Программа лояльности</Link>
                            </li>
                            <li className="footer__menu-item">
                                <Link to={"/chasto-zadavaemye-voprosy-otvety"}>Часто задаваемые вопросы-ответы</Link>
                            </li>
                            <li className="footer__menu-item">
                                <Link to={"/news"}>Новости</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="footer__column footer__column--third">
                        <ul className="footer__menu footer__menu--secondary">
                            <li className="footer__menu-item">
                                <Link to={"/rekvizity"}>Реквизиты</Link>
                            </li>
                            <li className="footer__menu-item">
                                <Link to={"/company"}>О компании</Link>
                            </li>
                            <li className="footer__menu-item">
                                <Link to={"/checkout"}>Как оформить заказ</Link>
                            </li>
                            <li className="footer__menu-item">
                                <Link to={"/oplata-i-dostavka"}>Оплата и доставка</Link>
                            </li>
                            <li className="footer__menu-item">
                                <Link to={"/warranty-and-return"}>Гарантия и возврат</Link>
                            </li>
                            <li className="footer__menu-item">
                                <Link to={"/eula"}>Пользовательское соглашение</Link>
                            </li>
                            <li className="footer__menu-item">
                                <Link to={"/privacy"}>Политика конфиденциальности</Link>
                            </li>
                            <li className="footer__menu-item">
                                <Link to={"/contacts"}>Контакты</Link>
                            </li>
                            <li className="footer__menu-item">
                                <Link to={"/dlya-postavshhikov"}>Для поставщиков</Link>
                            </li>
                            <li className="footer__menu-item">
                                <Link to={"/news"}>Новости</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="footer__column footer__column--fourth">
                        <div className="footer__buttons">
                            <Button className={'btn-white'} text={"Подписаться на рассылку"}/>
                            <Button className={'btn-orange--outline'} icon={feedback}
                                    text={"Помогите нам стать лучше"}/>
                        </div>
                        <div className="footer__menu--duplicate-2">
                            <ul className="footer__menu footer__menu--secondary">
                                <li className="footer__menu-item">
                                    <Link to={"/rekvizity"}>Реквизиты</Link>
                                </li>
                                <li className="footer__menu-item">
                                    <Link to={"/company"}>О компании</Link>
                                </li>
                                <li className="footer__menu-item">
                                    <Link to={"/checkout"}>Как оформить заказ</Link>
                                </li>
                                <li className="footer__menu-item">
                                    <Link to={"/oplata-i-dostavka"}>Оплата и доставка</Link>
                                </li>
                                <li className="footer__menu-item">
                                    <Link to={"/warranty-and-return"}>Гарантия и возврат</Link>
                                </li>
                                <li className="footer__menu-item">
                                    <Link to={"/eula"}>Пользовательское соглашение</Link>
                                </li>
                                <li className="footer__menu-item">
                                    <Link to={"/privacy"}>Политика конфиденциальности</Link>
                                </li>
                                <li className="footer__menu-item">
                                    <Link to={"/contacts"}>Контакты</Link>
                                </li>
                                <li className="footer__menu-item">
                                    <Link to={"/dlya-postavshhikov"}>Для поставщиков</Link>
                                </li>
                                <li className="footer__menu-item">
                                    <Link to={"/news"}>Новости</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-copyright">
            <div className="sidebar-copyright">
                <div className="sidebar-copyright__logo">
                    <img src={logo} alt=""/>
                </div>
                <div className="sidebar-copyright__text">
                    <span>© {context.user.state.legal || 'ИП Прожерин Иван Анатольевич'}, 2022</span>
                </div>
            </div>
        </div>
    </>
}

const FooterMobile = () => {
    return <div className="footer">
        <div className="footer__container container">

        </div>
    </div>
}

