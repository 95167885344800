import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import './static/css/style.css';
import 'react-loading-skeleton/dist/skeleton.css'

ReactDOM.render(
    <App />,
  document.getElementById('root')
);
