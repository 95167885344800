import {Screen} from "./screen.api";
import {Catalog} from "./catalog.api";
import {Pages} from "./pages.api";
import {User} from "./user.api";
import {Cart} from "./cart.api";
import {Content} from "./content.api";
import {Delivery} from "./delivery.api";

export const Processor = {
    screen: new Screen(),
    catalog: new Catalog(),
    pages: new Pages(),
    user: new User(),
    cart: new Cart(),
    content: new Content(),
    delivery: new Delivery()
};