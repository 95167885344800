import {useEffect} from 'react'
import {useLocation} from 'react-router-dom';
import Routes from './routing/routes'
import {AppContext, AppContextValue} from "./store/context";

import 'react-loading-skeleton/dist/skeleton.css'

import ym, {YMInitializer} from 'react-yandex-metrika'

export default function App() {
    const context = AppContextValue();

    useEffect(() => {
        if(context.modal.state.open && !context.modal.state.type.includes('topMenu')) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }

    }, [context.modal.state.open]);

    useEffect(() => {
        context.processor.screen.getScreen('init').then(res => {
            const city = res.cities.find(e => {
                if (res.city.selected === null) {
                    return e.id === res.city.resolved
                }

                return e.id === res.city.selected
            });

            context.user.dispatch({
                type: 'initialize',
                payload: {
                    customer: res.customer,
                    loaded: true,
                    askCity: res.city.selected == null,
                    cities: res.cities,
                    city: city,
                    legal: res.legal,
                }
            });

            context.catalog.dispatch({
                type: 'setCategories',
                payload: res.categories
            });

            context.catalog.dispatch({
                type: 'setNav',
                payload: res.navigation
            });
        });

        context.processor.cart.cart().then(res => {
            context.cart.dispatch({type: 'setCart', payload: res.cart});
        });
    }, []);

    return <AppContext.Provider value={{context}}>
        <YMInitializer accounts={[45109365]} options={{webvisor: true}}/>
        <Routes/>
    </AppContext.Provider>
}

export const usePageViews = () => {
    let location = useLocation();
    useEffect(() => {
        ym('hit', location.pathname);
    }, [location]);
};
