import {useHistory, useLocation} from 'react-router-dom'
import {AngleLeft, AngleRight} from "./Icons";

export const Pagination = ({current, pages}) => {
    current = current ? parseInt(current) : 1;
    current = !isNaN(current) ? current : 1;

    const history = useHistory();
    const location = useLocation();

    const map = {
        first: [],
        middle: [],
        last: [],
    };

    switch (true) {
        case current > pages:
        case current < 0:
        case pages === 0:
        case pages === 1:
            return <div/>;
        case pages === 2 :
            map.first.push(1, 2);
            break;
        case pages === 3 :
            map.first.push(1, 2, 3);
            break;
        default:
            map.first.push(1, 2);
            map.last.push(pages - 1, pages);
            break
    }

    const next = () => {
        const next = current + 1;
        if (next <= pages) {
            const params = new URLSearchParams(location.search);
            params.set('page', next)

            history.replace({pathname: location.pathname, search: params.toString()});
        }

    }

    const prev = () => {
        const prev = current - 1;
        if (prev >= pages) {
            const params = new URLSearchParams(location.search);
            params.set('page', prev)

            history.replace({pathname: location.pathname, search: params.toString()});
        }
    }

    const goTo = (page) => {
        const params = new URLSearchParams(location.search);
        params.set('page', page)

        history.replace({pathname: location.pathname, search: params.toString()});
    }


    const Pin = ({page}) => {
        return <div className={`pagination__button ${current == page ? 'pagination__button--active' : '' }`}>
            <div onClick={() => {
                goTo(page)
            }}>{page}</div>
        </div>
    };

    return <div className="pagination">
        <div className="pagination__container">
            <div className="pagination__prev" onClick={() => {
                prev()
            }}>
                <AngleLeft/>
            </div>
            <div className="pagination__list">
                {map.first.map(el => {
                    return <Pin page={el}/>
                })}

                {map.last.length > 0 ? <div className="pagination__button"><span>...</span></div> : null}

                {current >= 3 && current !== pages && current !== pages - 1 ? <>
                    {current !== 3 ? <Pin page={current - 1}/> : null}
                    <Pin page={current}/>
                    {current !== pages - 2 ? <Pin page={current + 1}/> : null}
                    <div className="pagination__button">
                        <span>...</span>
                    </div>
                </> : null
                }

                {map.last.map(el => {
                    return <Pin page={el}/>
                })}
            </div>
            <div className="pagination__next" onClick={() => {
                next()
            }}>
                <AngleRight/>
            </div>
        </div>
    </div>
};