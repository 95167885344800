import React from "react";
import {AppContext} from "../store/context";
import {LoaderFullsize} from "../components/Loader";
import {pluralize} from '../components/Helpers'
import {Link} from 'react-router-dom'
import {STATIC_HOST} from "../const";
import {Button} from "../components/ui/Button";

export class OrderPage extends React.Component {
    constructor() {
        super();

        this.state = {
            order: {},
            paymentUrl: null,
            loaded: false,
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        const number = this.props.match.params.order;

        this.context.context.processor.user.order(number)
            .then(res => {
                this.setState({order: res.order, loaded: true})
                return res;
            })
            .then(res => {
                if (res.hasOwnProperty('paymentUrl')) {
                    this.setState({paymentUrl: res.paymentUrl})
                }
            })
            .catch(err => {
                this.props.history.push('/');
            })
    }

    deliveryType(type) {
        switch (type) {
            case 'cdek':
                return 'Доставка СДЭК';
            case 'taxi':
                return 'Такси'
            case 'pickup':
                return 'Самовывоз'
            default:
                return type
        }
    }

    paymentType(type) {
        switch (type) {
            case 'online':
                return 'Онлайн';
            case 'cash':
                return 'Наличные'
            default:
                return type
        }
    }

    status(type) {
        switch (type) {
            case 1:
                return 'Новый';
            case 2:
                return 'В работе';
            case 3:
                return 'Ожидает оплаты';
            case 4:
                return 'Оплачен';
            case 5:
                return 'Доставляется';
            case 6:
                return 'Готов к выдаче';
            case 7:
                return 'Закрыт';
            case 8:
                return 'Отменен';
            default:
                return type
        }
    }


    productCount(products) {
        let count = 0;

        Object.values(products).forEach(el => {
            count += el.count;
        })

        return pluralize(count, ['товар', 'товара', 'товаров'])
    }

    render() {
        return this.state.loaded ? <div className={`personal-container container`}>
            <div className="personal-title">
                <h1 className="title">Заказ № {this.state.order.number}</h1>
            </div>
            {this.state.paymentUrl ?
                <div className="order-payment">
                    <Button className={"btn-green"} text={'Оплатить заказ'} onClick={() => {
                        window.location.replace(this.state.paymentUrl)
                    }}/>
                </div> : null}
            <div className="order-details">
                <div className="order-details__row">
                    <div className="order-details__row--key">Дата заказа</div>
                    <div className="order-details__row--value">{this.state.order.created}</div>
                </div>
                <div className="order-details__row">
                    <div className="order-details__row--key">Статус</div>
                    <div className="order-details__row--value">{this.status(this.state.order.status)}</div>
                </div>
                <div className="order-details__row">
                    <div className="order-details__row--key">Способ оплаты</div>
                    <div
                        className="order-details__row--value">{this.paymentType(this.state.order.payment.type)}</div>
                </div>
                {this.state.order.comment ?
                    <div className="order-details__row">
                        <div className="order-details__row--key">Комментарий</div>
                        <div className="order-details__row--value">{this.state.order.comment}</div>
                    </div> : null}
                <div className="order-details__title">Получение</div>
                <div className="order-details__row">
                    <div className="order-details__row--key">Способ получения</div>
                    <div
                        className="order-details__row--value">
                        {this.deliveryType(this.state.order.delivery.type)}
                        {this.state.order.delivery.address ? `, ${this.state.order.delivery.address}` : ''}</div>
                </div>
                <div className="order-details__row">
                    <div className="order-details__row--key">Сумма заказа</div>
                    <div className="order-details__row--value">{this.state.order.sum} ₽</div>
                </div>
                <div className="order-details__row">
                    <div className="order-details__row--key">Стоимость доставки</div>
                    <div className="order-details__row--value">{this.state.order.delivery.price} ₽</div>
                </div>
                <div className="order-details__title">Итого</div>
                <div className="order-details__row">
                    <div className="order-details__row--key">К оплате</div>
                    <div
                        className="order-details__row--value">{this.state.order.sum + this.state.order.delivery.price} ₽
                    </div>
                </div>
            </div>

            <div className="personal-section personal-orders">
                <div className="personal-section__title">
                    <h3>Состав заказа</h3>
                </div>
                <div className="personal-section__body">
                    <div className="order-products">
                        <div className="order-products__body">
                            {Object.values(this.state.order.products).map(el => {
                                return <Link to={`/product/${el.slug}`} className="order-products__row--order">
                                    <div className="order-products__col order-products__col--picture">
                                        <img src={STATIC_HOST + 'products/' + el.image} alt=""/>
                                        {el.title}</div>
                                    <div className="order-products__col">{el.count} шт.<br/>{el.price} ₽ / шт.
                                    </div>
                                    <div className="order-products__col">{el.sum} ₽</div>
                                </Link>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div> : <LoaderFullsize/>
    }
}

OrderPage.contextType = AppContext;