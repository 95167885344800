import {Main} from "../layouts/Main"
import {MainPage} from "../views/Main";
import {Category as CategoryPage} from "../views/catalog/Category";
import {Product as ProductPage} from "../views/catalog/Product";
import {TestimonialsPage} from "../views/content/Testimonials";
import {ActionsPage} from "../views/content/Actions";
import {NewsPage} from "../views/content/News";
import {CartPage} from "../views/Cart";
import {ArticlePage} from "../views/content/Article";
import {ContactsPage} from "../views/content/Contacts";
import {ClubPage} from "../views/content/club/Club";
import {VacanciesPage} from "../views/content/Vacancies";
import {ClubArticle} from "../views/content/club/ClubArticle";
import {Search} from "../views/catalog/Search";
import {PersonalPage} from "../views/Personal";
import {OrderPage} from "../views/Order";

export const publicRoutes = [
    {
        path: "/",
        layout: Main,
        component: MainPage
    },
    {
        path: "/catalog/search",
        layout: Main,
        component: Search
    },
    {
        path: "/catalog/*",
        layout: Main,
        component: CategoryPage
    },
    {
        path: "/product/:slug",
        layout: Main,
        component: ProductPage
    },
    {
        path: "/favorite-customer-book",
        layout: Main,
        component: TestimonialsPage,
    },
    {
        path: "/news",
        layout: Main,
        component: NewsPage,
    },
    {
        path: ["/actions/*", "/news/*" ],
        layout: Main,
        component: ArticlePage,
    },
    {
        path: "/actions",
        layout: Main,
        component: ActionsPage,
    },
    {
        path: "/contacts",
        layout: Main,
        component: ContactsPage,
    },
    {
        path: "/club",
        layout: Main,
        component: ClubPage,
    },
    {
        path: "/club/*",
        layout: Main,
        component: ClubArticle,
    },
    {
        path: "/vacancies",
        layout: Main,
        component: VacanciesPage,
    },
    {
        path: "/cart",
        layout: Main,
        component: CartPage,
    },
    {
        path: "/personal",
        layout: Main,
        component: PersonalPage,
    },
    {
        path: "/personal/order/:order",
        layout: Main,
        component: OrderPage,
    }
];

export default [
    ...publicRoutes
]
