import React from 'react'
import {AppContext} from "../../store/context";
import {Breadcrumbs} from "../../components/ui/Breadcrumbs";
import {Title} from "../../components/ui/Title";
import {ActionCard} from "../../components/content/Action";
import {Pagination} from "../../components/ui/Pagination";
import Skeleton from 'react-loading-skeleton'

export class ActionsPage extends React.Component {
    constructor() {
        super();

        this.state = {
            loaded: false,
            page: {
                current: 0,
                count: 0
            },
            actions: [
                {
                    startAt: <Skeleton width={100}/>,
                    title: <Skeleton width={350}/>,
                    preview: <Skeleton count={2} width={550}/>
                },
                {
                    startAt: <Skeleton width={100}/>,
                    title: <Skeleton width={250}/>,
                    preview: <Skeleton count={3} width={550}/>
                },
                {
                    startAt: <Skeleton width={100}/>,
                    title: <Skeleton width={350}/>,
                    preview: <Skeleton count={2} width={550}/>
                },
                {
                    startAt: <Skeleton width={100}/>,
                    title: <Skeleton width={350}/>,
                    preview: <Skeleton count={2} width={550}/>
                }
            ]
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        this.context.context.processor.content.actions().then(res => {
            this.setState({
                actions: res.actions,
                loaded: true,
            })
        })
    }
    render() {
        return <div className="actions-wrapper">
            <div className="actions-container container">
                <div className="actions-breadcrumbs">
                    <Breadcrumbs parts={[{title: 'Главная', slug: '/'}, {title: 'Акции'}]}/>
                </div>
                <div className="actions-title">
                    <Title title={'Акции'}/>
                </div>
                <div className="actions-list">
                        {this.state.actions.map(el => {
                            return <ActionCard item={el}/>
                        })}
                    </div>
                    <div className="actions-pagination">
                        <Pagination current={this.state.page.current} pages={this.state.page.count}/>
                    </div>
            </div>
        </div>
    }
}

ActionsPage.contextType = AppContext