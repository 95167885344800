import {useContext} from 'react'
import {Link} from 'react-router-dom'
import {Modal} from "./Modal";
import {Button} from "../ui/Button";
import {AppContext} from "../../store/context";

export const FeedbackModal = () => {
    const {context} = useContext(AppContext)

    const isOpen = () => {
        return context.modal.state.open && context.modal.state.type === 'feedback'
    };

    return <Modal className={`feedback-modal ${isOpen() ? 'open' : ''}`}>
        <div className="feedback-modal__title">Обратная связь</div>
            <div className="form feedback-modal__form">
                <div className="form-field">
                    <input className={'form-field__input'} type={'text'} placeholder={'Имя'}/>
                </div>
                <div className="form-field">
                    <input className={'form-field__input col-1-2'} type={'text'} placeholder={'Телефон'}/>
                    <input className={'form-field__input col-1-2'} type={'text'} placeholder={'Email'}/>
                </div>

                <div className="form-field">
                    <textarea className={'form-field__input'} placeholder={'Сообщение'}/>
                </div>
            </div>
        <div className="feedback-modal__button">
            <Button className={"btn-green--outline"} text={"Отправить сообщение"}/>
        </div>
        <div className="feedback-modal__disclamer">
            Нажимая кнопку «Отправить», вы соглашаетесь с обработкой персональных данных, <Link target={'_blank'} to={'/privacy'}>политикой
            конфиденциальности</Link> и <Link target={'_blank'} to={'/eula'}> пользовательским соглашением</Link>.
        </div>
    </Modal>
}