import React from "react";
import {AppContext} from "../store/context";
import {LoaderFullsize} from "../components/Loader";
import {Button} from "../components/ui/Button";
import InputMask from 'react-input-mask';
import {pluralize} from '../components/Helpers'
import {Link} from 'react-router-dom'

export class PersonalPage extends React.Component {
    constructor() {
        super();

        this.state = {
            customer: {},
            orders: {},
            loaded: false,
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        this.context.context.processor.user.load()
            .then(res => {
                this.setState({customer: res.customer, orders: res.orders, loaded: true})
            })
            .catch(err => {
                this.props.history.push('/');
            })
    }

    deliveryType(type) {
        switch (type) {
            case 'cdek':
                return 'Доставка СДЭК';
            case 'taxi':
                return 'Такси'
            case 'pickup':
                return 'Самовывоз'
            default:
                return type
        }
    }

    paymentType(type) {
        switch (type) {
            case 'online':
                return 'Онлайн';
            case 'cash':
                return 'Наличные'
            default:
                return type
        }
    }

    status(type) {
        switch (type) {
            case 1:
                return 'Новый';
            case 2:
                return 'В работе';
            case 3:
                return 'Ожидает оплаты';
            case 4:
                return 'Оплачен';
            case 5:
                return 'Доставляется';
            case 6:
                return 'Готов к выдаче';
            case 7:
                return 'Закрыт';
            case 8:
                return 'Отменен';
            default:
                return type
        }
    }

    productCount(products) {
        let count = 0;

        Object.values(products).forEach(el => {
            count += el.count;
        })

        return pluralize(count, ['товар', 'товара', 'товаров'])
    }

    updateCustomer() {
        this.context.context.processor.user.update(this.state.customer).then(res => {
            this.setState({customer: res.customer});

            this.context.context.toast.success('Данные успешно сохранены')
        }).catch(err => {
            this.context.context.toast.error(err.message)
        })
    }

    render() {
        return this.state.loaded ? <div className={`personal-container container`}>
            <div className="personal-title">
                <h1 className="title">Личный кабинет</h1>
                <Button className={'btn-green--outline'} text={'Выйти из аккаунта'} onClick={() => {

                }}/>
            </div>
            <div className="personal-section personal-data">
                <div className="personal-section__title">
                    <h3>Личные данные</h3>
                    {this.state.customer.points && this.state.customer.points > 0 ?
                    <div className="personal-section__title--bonuses">
                        Бонусных баллов: <span>{this.state.customer.points}</span>
                    </div> : null}
                </div>
                <div className="personal-section__body">
                    <InputMask required={true} className={'form-field__input'} value={this.state.customer.phone}
                               placeholder={'+7 (___) ___ __ __'}
                               mask="+7 (999) 999 99 99"
                               maskChar="_"
                               disabled={true}
                    />

                    <input required={true} className={'form-field__input'} type={'text'}
                           onChange={(e) => {
                               this.setState({
                                   ...this.state, customer: {
                                       ...this.state.customer,
                                       name: e.currentTarget.value
                                   }
                               })
                           }}
                           placeholder={'Имя'} value={this.state.customer.name}

                    />
                    <input required={true} className={'form-field__input'} type={'text'}
                           onChange={(e) => {
                               this.setState({
                                   ...this.state, customer: {
                                       ...this.state.customer,
                                       card: e.currentTarget.value
                                   }
                               })
                           }}
                           placeholder={'Номер бонусной карты'} value={this.state.customer.card}
                    />
                    <input required={true} className={'form-field__input'} type={'text'}
                           onChange={(e) => {
                               this.setState({
                                   ...this.state, customer: {
                                       ...this.state.customer,
                                       email: e.currentTarget.value
                                   }
                               })
                           }}
                           placeholder={'Email'} value={this.state.customer.email}
                    />
                </div>
                <Button className={'btn-green--outline'} text={'Сохранить изменения'} onClick={() => {
                    this.updateCustomer()
                }}/>
            </div>
            <hr/>
            <div className="personal-section personal-orders">
                <div className="personal-section__title">
                    <h3>Мои заказы</h3>
                </div>
                <div className="personal-section__body">
                    <div className="personal-orders__row--header">
                        <div className="personal-orders__col">Дата создания</div>
                        <div className="personal-orders__col">Статус</div>
                        <div className="personal-orders__col">Оплата</div>
                        <div className="personal-orders__col">Способ получения</div>
                        <div className="personal-orders__col">Итого</div>
                    </div>
                    {this.state.orders.map(el => {
                        return <Link to={`/personal/order/${el.number}`} className="personal-orders__row--order">
                            <div className="personal-orders__col">
                                <strong>{el.created}</strong><br/>{this.productCount(el.products)}</div>
                            <div className="personal-orders__col">{this.status(el.status)}</div>
                            <div className="personal-orders__col">{this.paymentType(el.payment.type)}</div>
                            <div
                                className="personal-orders__col">{this.deliveryType(el.delivery.type)}<br/>{el.delivery.address}
                            </div>
                            <div className="personal-orders__col"><strong>{el.sum} ₽</strong></div>
                        </Link>
                    })}
                </div>
            </div>
        </div> : <LoaderFullsize/>
    }
}

PersonalPage.contextType = AppContext;