import React from 'react'
import {AppContext} from "../../store/context";
import {Breadcrumbs} from "../../components/ui/Breadcrumbs";
import {Title} from "../../components/ui/Title";
import {TestimonialsCard} from "../../components/content/Testimonials";

export class TestimonialsPage extends React.Component {
    constructor() {
        super()

        this.state = {
            testimonials: [],
            loaded: false,
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        this.context.context.processor.content.testimonials().then(res => {
            this.setState({
                testimonials: res.testimonials,
                loaded: true,
            })
        })
    }

    render() {
        return <div className="testimonials-wrapper">
            <div className="testimonials-container container">
                <div className="testimonials-breadcrumbs">
                    <Breadcrumbs parts={[{title: 'Главная', slug: '/'}, {title: 'Книга любимого покупателя'}]}/>
                </div>
                <div className="testimonials-title">
                    <Title title={'Книга любимого покупателя'}/>
                </div>
                <div className="testimonials-list">
                    {this.state.testimonials.map(el => {
                        return <TestimonialsCard item={el}/>
                    })}
                </div>
            </div>
        </div>
    }
}

TestimonialsPage.contextType = AppContext