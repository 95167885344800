import {useContext, useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {Modal} from "./Modal";
import {Button} from "../ui/Button";
import {AppContext} from "../../store/context";
import {Loader} from "../Loader";
import InputMask from 'react-input-mask';
import AsyncSelect from 'react-select/async';
import Select from 'react-select'
import {pluralize} from '../Helpers'

const initForm = {
    user: {
        phone: null,
        email: null,
        name: null,
        certificate: null,
        hasBonusCard: false,
    },
    delivery: {
        type: 'pickup',
        params: {}
    },
    payment: {
        type: 'cash'
    },
    comment: null,
};

export const CheckoutModal = () => {
    const {context} = useContext(AppContext);

    const isOpen = () => {
        return context.modal.state.open && context.modal.state.type === 'checkout'
    };

    const [checkoutData, setCheckoutData] = useState(null);
    const [form, setForm] = useState({...initForm});
    const [bonuses, setBonuses] = useState(null);

    useEffect(() => {
        if (isOpen()) {
            if (!context.modal.state.params.shop) {
                context.toast.error("Пожалуйста, выберите магазин");
                context.modal.dispatch({type: 'closeModal'});
                return;
            }

            context.processor.cart.preCheckout({
                shop: context.modal.state.params.shop,
                products: context.modal.state.params.products
            }).then(res => {
                if(res.customer.phone) {
                    setForm({
                        ...form,
                        user: {
                            ...form.user,
                            hasBonusCard: res.customer.has_bonus_card,
                        }
                    })
                }

                setCheckoutData(res)
            }).catch(err => {
                context.toast.error("Что-то пошло не так.. Пожалуйста, попробуйте позднее");
                context.modal.dispatch({type: 'closeModal'});
                return;
            })
        } else {
            setForm({...initForm});
            setCheckoutData(null);
            setBonuses(null)
        }
    }, [context.modal.state.open]);


    useEffect(() => {
        if(form.user.hasBonusCard) {
            context.processor.cart.calculateBonuses({
                shop: context.modal.state.params.shop,
                products: context.modal.state.params.products
            }).then(res => {
                setBonuses(res.bonuses)
            }).catch(err => {
                return;
            })
        }
    }, [form.user.hasBonusCard])
    const setDelivery = (type) => {
        setForm({...form, delivery: {...form.delivery, type: type, params: {}}})
    };

    const setPayment = (type) => {
        setForm({...form, payment: {...form.payment, type: type}})
    };

    const calculateSum = () => {
        let sum = 0;

        checkoutData.products.map(el => {
            sum += el.sum
        })

        return sum
    }

    const calculateCount = () => {
        let sum = 0;

        checkoutData.products.map(el => {
            sum += el.count
        })

        return sum
    }

    const checkout = () => {
        if (!form.user.email) {
            context.toast.warning("Пожалуйста, укажите email");
            return;
        }

        if (!form.user.name) {
            context.toast.warning("Пожалуйста, укажите имя");
            return;
        }

        const phone = form.user.phone;

        if (phone.length < 11) {
            context.toast.warning('Пожалуйста, проверьте корректность введенного номера телефона');
            return;
        }

        const phoneUnmasked = phone.match(/\d+/g).join('');

        if (phoneUnmasked.length < 11) {
            context.toast.warning('Пожалуйста, проверьте корректность введенного номера телефона');
            return;
        }

        if (form.delivery.type === 'cdek') {
            if (!form.delivery.params.type) {
                context.toast.warning('Пожалуйста, проверьте корректность введенного адреса доставки');
                return;
            }

            if (form.delivery.params.type === 'courier' && (!form.delivery.params.address.street ||
                !form.delivery.params.address.house ||
                form.delivery.params.address.street.length < 2 ||
                form.delivery.params.address.house.length < 1)) {
                context.toast.warning('Пожалуйста, проверьте корректность введенного адреса доставки');
                return;
            }
        }

        if (form.delivery.type === 'taxi') {
            if (form.delivery.params.address.length < 2) {
                context.toast.warning('Пожалуйста, проверьте корректность введенного адреса доставки');
                return;
            }
        }

        context.processor.cart.checkout({
            ...form,
            products: checkoutData.products,
            shop: checkoutData.shop
        }).then(res => {
            context.modal.state.params.success()
            context.toast.success(`Заказ ${res.order.number} успешно оформлен`);
            context.modal.dispatch({type: 'closeModal'});
            return;
        }).catch(e => {
            context.toast.error(e.message)
        })
    };

    const setDeliveryParams = (params) => {
        setForm({
            ...form, delivery: {...form.delivery, params: params}
        })
    };

    return <Modal className={`checkout-modal ${isOpen() ? 'open' : ''}`}>
        <div className="checkout-modal__title">Оформление заказа</div>
        {checkoutData !== null ?
            <div className="checkout-modal__inner">
                <div className="checkout-modal__block checkout-modal__block--info">
                    <div className="checkout-modal__block-title">Укажите данные получателя заказа</div>
                    <div className="checkout-modal__block-body">
                        <div className="form checkout-modal__form">
                            <div className="form-field">
                                <InputMask required={true} className={'form-field__input col-1-2'} value={form.phone}
                                           placeholder={'+7 (___) ___ __ __'}
                                           mask="+7 (999) 999 99 99"
                                           maskChar="_" onChange={(e) => {
                                    setForm({...form, user: {...form.user, phone: e.currentTarget.value}})
                                }}/>
                                <input required={true} className={'form-field__input col-1-2'} type={'text'}
                                       value={form.email}
                                       placeholder={'Email'}
                                       onChange={(e) => {
                                           setForm({...form, user: {...form.user, email: e.currentTarget.value}})
                                       }}
                                />
                            </div>
                            <div className="form-field">
                                <input required={true} className={'form-field__input'} type={'text'} placeholder={'Имя'}
                                       value={form.name}
                                       onChange={(e) => {
                                           setForm({...form, user: {...form.user, name: e.currentTarget.value}})
                                       }}
                                />
                            </div>
                            <div className="form-field">
                                <textarea className={'form-field__input'} placeholder={'Комментарий'}
                                          onChange={(e) => {
                                              setForm({...form, comment: e.currentTarget.value})
                                          }}
                                />
                            </div>
                            <div className="form-field">
                                <input className={'form-field__input'} type={'text'} placeholder={'Номер сертификата'}
                                       onChange={(e) => {
                                           setForm({...form, certificate: e.currentTarget.value})
                                       }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="checkout-modal__block checkout-modal__block--delivery">
                    <div className="checkout-modal__block-title">Выберите способ получения</div>
                    {form.delivery.type === 'pickup'
                        ? <div className={'checkout-modal__block-subtitle'}>
                            Выбранный магазин: <strong>{checkoutData.shop.title}, {checkoutData.shop.address}</strong>
                        </div>
                        : null}
                    <div className="checkout-modal__block-body">
                        <div className="checkout-modal__block-buttons">
                            <div
                                className={`checkout-modal__block-button ${form.delivery.type === 'pickup' ? 'checked' : ''}`}
                                onClick={() => {
                                    setDelivery('pickup')
                                }}>
                                <div className="checkout-modal__block-button-title">Самовывоз</div>
                                <div className="checkout-modal__block-button-descr">Сегодня и в течение 3 дней</div>
                            </div>
                            {checkoutData.delivery.map(el => {
                                switch (el.type) {
                                    case 'cdek':
                                        return <div
                                            className={`checkout-modal__block-button ${form.delivery.type === 'cdek' ? 'checked' : ''}`}
                                            onClick={() => {
                                                setDelivery('cdek')
                                            }}>
                                            <div className="checkout-modal__block-button-title">Доставка курьером</div>
                                            <div className="checkout-modal__block-button-descr">Завтра и позже</div>
                                        </div>
                                    case 'taxi':
                                        return <div
                                            className={`checkout-modal__block-button ${form.delivery.type === 'taxi' ? 'checked' : ''}`}
                                            onClick={() => {
                                                setDelivery('taxi')
                                            }}>
                                            <div className="checkout-modal__block-button-title">Такси</div>
                                            <div className="checkout-modal__block-button-descr">В течение дня</div>
                                        </div>
                                }
                            })}
                        </div>
                        {form.delivery.type === 'cdek' ? <CdekDelivery callback={setDeliveryParams}/> : null}
                        {form.delivery.type === 'taxi' ? <TaxiDelivery callback={setDeliveryParams}/> : null}
                    </div>
                </div>
                <div className="checkout-modal__block checkout-modal__block--payment">
                    <div className="checkout-modal__block-title">Выберите удобный способ оплаты</div>
                    <div className="checkout-modal__block-body">
                        <div className="checkout-modal__block-buttons">
                            <div
                                className={`checkout-modal__block-button ${form.payment.type === 'cash' ? 'checked' : ''}`}
                                onClick={() => {
                                    setPayment('cash')
                                }}>
                                <div className="checkout-modal__block-button-title">При получении</div>
                                <div className="checkout-modal__block-button-descr">Наличными, картой</div>
                            </div>
                            {checkoutData.payment && checkoutData.payment.type === 'Robokassa' ?
                                <div
                                    className={`checkout-modal__block-button ${form.payment.type === 'online' ? 'checked' : ''}`}
                                    onClick={() => {
                                        setPayment('online')
                                    }}>
                                    <div className="checkout-modal__block-button-title">Онлайн</div>
                                    <div className="checkout-modal__block-button-descr">Робокасса</div>
                                </div> : null}
                        </div>
                    </div>
                </div>
                <div className="checkout-modal__block checkout-modal__block--total">
                    <div className="checkout-modal__block--sticky">
                        <div className="checkout-modal__block-summary">
                            <div className="checkout-modal__block-title">Ваш заказ</div>
                            <div className="checkout-modal__block-body">
                                <div className="checkout-modal__total-items">
                                    <div className="checkout-modal__total-item">
                                        <div className="checkout-modal__total-item--label">Товары ({calculateCount()})</div>
                                        <div className="checkout-modal__total-item--value">{calculateSum()} ₽</div>
                                    </div>
                                    {bonuses && bonuses > 0 ? <>
                                    <div className="checkout-modal__total-item checkout-modal__total-item--bonuses">
                                    <div className="checkout-modal__total-item--label">Будет начислено</div>
                                    <div className="checkout-modal__total-item--value">{pluralize(bonuses, ['балл', 'балла', 'баллов'])}</div>
                                    </div>
                                    </> : null}
                                    <div className="checkout-modal__total-item checkout-modal__total-item--sum">
                                        <div className="checkout-modal__total-item--label">Итого к оплате</div>
                                        <div className="checkout-modal__total-item--value">{calculateSum()} ₽</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {form.delivery.params.hasOwnProperty('calculated') && form.delivery.params.calculated === false ?
                            <div className="checkout-modal__block-disclamer">
                                В сумму вашего заказа не включена стоимость доставки. В ближайшее время наш менеджер
                                свяжется с вами для расчета доставки. После этого итоговая сумма заказа отразится в личном
                                кабинете.
                            </div> : null}
                    </div>

                </div>
                <div className="checkout-modal__block checkout-modal__block--order">
                    <div className="checkout-modal__order-disclamer">
                        Нажимая кнопку «Отправить», вы соглашаетесь с обработкой персональных данных, <Link
                        target={"_blank"} to={'/privacy'}>политикой
                        конфиденциальности</Link> и <Link target={"_blank"} to={'/eula'}> пользовательским
                        соглашением</Link>.
                    </div>
                    <div className="checkout-modal__order-button">
                        <Button onClick={() => {
                            checkout()
                        }} className={"btn-green--filled"} text={"Создать заказ"}/>
                    </div>
                </div>
            </div> : <Loader/>}
    </Modal>
};


const CheckoutPayment = () => {

};

const CdekDelivery = ({callback}) => {
    const {context} = useContext(AppContext);

    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()

    const [city, setCity] = useState(null);
    const [type, setType] = useState('point');

    const [deliveryPoints, setDeliveryPoints] = useState([]);

    const searchCdekCity = (query, cl) => {
        if (query.length < 3) {
            return;
        }

        context.processor.delivery.cdekSearch(query).then(res => {
            cl(res.cities.map(el => {
                return {label: el.title, value: el.id}
            }))
        })
    };

    useEffect(() => {
        context.processor.delivery.cdekPoints(city).then(res => {
            setDeliveryPoints(res.points.map(el => {
                return {label: `${el.title} (${el.address})`, value: el.code}
            }));
        })
    }, [city]);

    const saveDelivery = ({city, address, pickpoint, type}) => {
        callback({city: city, address: address, pickpoint: pickpoint, type: type, calculated: false})
    }

    const aggAddress = () => {
        return {street: ref1.current.value, house: ref2.current.value, flat: ref3.current.value}
    }

    return <div className={'checkout-modal__shipping-address'}>
        <div className="checkout-modal__shipping-address--title">Выберите город доставки</div>
        <div className="checkout-modal__shipping-address--selector">
            <AsyncSelect
                noOptionsMessage={() => {
                    return 'Введите название'
                }}
                loadingMessage={() => {
                    return 'Поиск..'
                }} loadOptions={searchCdekCity}
                onChange={(option) => {
                    setCity(option.value)
                }}
                placeholder={'Введите название'}
            />
        </div>
        {city ? <div className="checkout-modal__shipping-address--radio">
                <div className="checkout-modal__shipping-address--radio-item" onClick={() => {
                    setType('point')
                }}>
                    <input className={"cart-shop__checkbox"} type="radio" checked={type === 'point'} name={'type'}
                           value={'point'}/>
                    <span>Пункт выдачи CDEK</span>
                </div>
                <div className="checkout-modal__shipping-address--radio-item" onClick={() => {
                    setType('courier')
                }}>
                    <input className={"cart-shop__checkbox"} type="radio"
                           checked={type === 'courier'} name={'type'}
                           value={'courier'}/>
                    <span>До двери</span>
                </div>
            </div>
            : null}
        {city && type === 'point' ?
            <div className="checkout-modal__shipping-address--point">
                <Select
                    options={deliveryPoints}
                    onChange={(option) => {
                        saveDelivery({city: city, pickpoint: option.value, address: option.label, type: type, calculated: false});
                    }}
                    placeholder={'Пункт выдачи'}
                />
            </div>
            : null}
        {city && type === 'courier' ?
            <div className="checkout-modal__shipping-address--courier">
                <input ref={ref1} className={'form-field__input'} type={'text'} placeholder={'Улица'}
                       onChange={(option) => {
                           saveDelivery({city: city, pickpoint: null, address: aggAddress(), type: type, calculated: false});
                       }}
                />
                <input ref={ref2} className={'form-field__input'} type={'text'} placeholder={'Дом'}
                       onChange={(option) => {
                           saveDelivery({city: city, pickpoint: null, address: aggAddress(), type: type, calculated: false});
                       }}
                />
                <input ref={ref3} className={'form-field__input'} type={'text'} placeholder={'Квартира'}
                       onChange={(option) => {
                           saveDelivery({city: city, pickpoint: null, address: aggAddress(), type: type, calculated: false});
                       }}
                />
            </div>
            : null}

    </div>
}

const TaxiDelivery = ({callback}) => {
    return <div className={'checkout-modal__shipping-address'}>
        <div className="checkout-modal__shipping-address--title">Укажите адрес доставки</div>
        <div className="checkout-modal__shipping-address--selector">
            <input className={'form-field__input'} type={'text'} placeholder={'Адрес доставки'}
                   onChange={(e) => {
                       callback({address: e.currentTarget.value, calculated: false})
                   }}
            />
        </div>
    </div>

}