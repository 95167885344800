import React from "react";
import {Breadcrumbs} from "../../components/ui/Breadcrumbs";
import {AppContext} from "../../store/context";
import {Collapse} from 'react-collapse';

export class VacanciesPage extends React.Component {
    constructor() {
        super()

        this.state = {
            contacts: [],
            vacancies: [],
            loaded: false,
            open: null
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        this.context.context.processor.content.vacancies().then(res => {
            this.setState({
                vacancies: res.vacancies,
                loaded: true,
            })
        })
    }

    render() {
        return <div className="vacancies-wrapper">
            <div className="vacancies-container container">
                <div className="vacancies-breadcrumbs">
                    <Breadcrumbs parts={[{title: 'Главная', slug: '/'}, {title: 'Работа в Универсаде'}]}/>
                </div>
                <div className="vacancies-image">
                    <img
                        src={"https://universad.ru/cache/gen/1136x400_cc/public/uploads/images/0893f4409e711f3558a74c48f685fcd2_077f7aa3f0.jpg?s=WGYaHJEzwTKyw94hEQauqg"}
                        alt=""/>
                </div>
                <div className="vacancies-inner">
                    <div className="vacancies-text">
                        <h1> Работа в Универсаде</h1>

                        <p>Успех компании напрямую зависит от работающих в ней людей. Мы уверены, что сотрудники
                            "Универсада" профессионалы своего дела, готовы развиваться, искать нестандартные идеи,
                            чувствовать темп компании и двигать её вперёд.</p>
                        <p> Мы ищем ответственных, инициативных, отзывчивых и готовых показывать результат людей. Именно
                            они
                            получают возможность двигаться вперёд по карьерной лестнице и достигать новых высот.</p>
                        <p> Важный факт:<br/>
                            98% руководителей выросли внутри компании, возможно именно ТЫ достоин быть в их числе!
                        </p>
                    </div>
                    <div className="vacancies-contacts">
                        <div className="vacancies-contact">
                            <div className="vacancies-contact__title">Валерия Наумова</div>
                            <div className="vacancies-contact__email"><span
                                className="vacancies-contact__label">E-mail:</span> personal@universad.ru
                            </div>
                            <div className="vacancies-contact__phone"><span
                                className="vacancies-contact__label">Телефон:</span> 79842005124
                            </div>
                        </div>
                    </div>
                </div>
                <h1 className="">Открытые вакансии</h1>
                <div className="vacancies-list">
                    {this.state.vacancies.map((el, i) => {
                        return <>
                            <div className="vacancies-item" >
                                <div className="vacancies-item-heading" onClick={() => {
                                    this.state.open === i ? this.setState({open: null}) : this.setState({open: i})
                                }}>
                                    <div className="vacancies-item__title">
                                        {el.title}
                                    </div>
                                    <div className="vacancies-item__salary">
                                        {el.salary}
                                    </div>
                                </div>
                                <div className={`vacancies-item-description ${this.state.open === i ? 'active' : ''}`}>
                                    <Collapse isOpened={this.state.open === i}>
                                        <div className="vacancy-description__body">
                                            <div className="vacancy-description__section">
                                                <div className="vacancy-description__title">Уровень оплаты труда:</div>
                                                <div className="vacancy-description__text">
                                                    {el.salary}
                                                </div>
                                            </div>
                                            <div className="vacancy-description__section">
                                                <div className="vacancy-description__title">Описание вакансии:</div>
                                                <div className="vacancy-description__text"
                                                     dangerouslySetInnerHTML={{__html: el.description}}/>
                                            </div>
                                            <div className="vacancy-description__section">
                                                <div className="vacancy-description__title">Требования к соискателю:
                                                </div>
                                                <div className="vacancy-description__text"
                                                     dangerouslySetInnerHTML={{__html: el.requirements}}/>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>

                            </div>
                        </>
                    })}
                </div>
            </div>
        </div>
    }
}

VacanciesPage.contextType = AppContext;