import React from 'react'
import {AppContext} from "../store/context";

import {Menu} from '../components/catalog/Menu'
import {BannerSlider, NewsSlider, ProductSliderMultiple} from '../components/Slider'
import {TestimonialsMainGrid} from "../components/content/Testimonials";
import {Banner} from "../components/content/Banner";

import Skeleton from 'react-loading-skeleton'

export class MainPage extends React.Component {
    constructor() {
        super();

        this.state = {
            news: [
                {
                    startAt: <Skeleton width={100}/>,
                    title: <Skeleton width={320}/>,
                    preview: <Skeleton count={2} width={550}/>
                },
                {
                    startAt: <Skeleton width={100}/>,
                    title: <Skeleton width={250}/>,
                    preview: <Skeleton count={3} width={550}/>
                },
            ],
            testimonials: [],
            banners: [
                {
                    title: <Skeleton width={320}/>,
                    text: <Skeleton count={2} width={550}/>
                },
            ],
            collections: {
                mainOne: [
                    {title: <Skeleton width={200} count={2}/>, slug: null, price: <Skeleton width={60}/>},
                    {title: <Skeleton width={200} count={2}/>, slug: null, price: <Skeleton width={60}/>},
                    {title: <Skeleton width={200} count={2}/>, slug: null, price: <Skeleton width={60}/>},
                    {title: <Skeleton width={200} count={2}/>, slug: null, price: <Skeleton width={60}/>},
                    {title: <Skeleton width={200} count={2}/>, slug: null, price: <Skeleton width={60}/>},
                    {title: <Skeleton width={200} count={2}/>, slug: null, price: <Skeleton width={60}/>},
                    {title: <Skeleton width={200} count={2}/>, slug: null, price: <Skeleton width={60}/>},
                    {title: <Skeleton width={200} count={2}/>, slug: null, price: <Skeleton width={60}/>},
                ],
                mainTwo: [
                    {title: <Skeleton width={200} count={2}/>, slug: null, price: <Skeleton width={60}/>},
                    {title: <Skeleton width={200} count={2}/>, slug: null, price: <Skeleton width={60}/>},
                    {title: <Skeleton width={200} count={2}/>, slug: null, price: <Skeleton width={60}/>},
                    {title: <Skeleton width={200} count={2}/>, slug: null, price: <Skeleton width={60}/>},
                    {title: <Skeleton width={200} count={2}/>, slug: null, price: <Skeleton width={60}/>},
                    {title: <Skeleton width={200} count={2}/>, slug: null, price: <Skeleton width={60}/>},
                    {title: <Skeleton width={200} count={2}/>, slug: null, price: <Skeleton width={60}/>},
                    {title: <Skeleton width={200} count={2}/>, slug: null, price: <Skeleton width={60}/>},
                ],
                mainThree: [
                    {title: <Skeleton width={200} count={2}/>, slug: null, price: <Skeleton width={60}/>},
                    {title: <Skeleton width={200} count={2}/>, slug: null, price: <Skeleton width={60}/>},
                    {title: <Skeleton width={200} count={2}/>, slug: null, price: <Skeleton width={60}/>},
                    {title: <Skeleton width={200} count={2}/>, slug: null, price: <Skeleton width={60}/>},
                    {title: <Skeleton width={200} count={2}/>, slug: null, price: <Skeleton width={60}/>},
                    {title: <Skeleton width={200} count={2}/>, slug: null, price: <Skeleton width={60}/>},
                    {title: <Skeleton width={200} count={2}/>, slug: null, price: <Skeleton width={60}/>},
                    {title: <Skeleton width={200} count={2}/>, slug: null, price: <Skeleton width={60}/>},
                ]
            }
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        this.context.context.processor.screen.getScreen('main').then(res => {
            this.setState({
                banners: res.banners,
                testimonials: res.testimonials,
                news: res.news,
                collections: res.collections
            })
        })
    }

    render() {
        return <div className={"main-wrapper"}>
            <div className="main-container container">
                <div className="main-section main-section--banner">
                    <div className="main-catalog">
                        <Menu/>
                    </div>
                    <div className="main-banner">
                        <div className="main-banner__container">
                            {this.context.context.settings.isMobile
                                ? this.state.banners.map(el => {
                                    return <Banner item={el}/>
                                })
                                : <BannerSlider slides={this.state.banners}/>}
                        </div>
                    </div>
                </div>
                <div className="main-section main-section--products">
                    <ProductSliderMultiple title={'Выбор покупателей'} products={this.state.collections.mainOne}/>
                </div>
                <div className="main-section main-section--products">
                    <ProductSliderMultiple title={'Лучшие предложения'} products={this.state.collections.mainTwo}/>
                </div>
                <div className="main-section main-section--products">
                    <ProductSliderMultiple title={'Новинки'} products={this.state.collections.mainThree}/>
                </div>
                <div className="main-section main-section--news">
                    <NewsSlider title={"Последние новости"} news={this.state.news}/>
                </div>
                <div className="main-section main-section--testimonials">
                    <TestimonialsMainGrid title={"Книга любимого покупателя"} testimonials={this.state.testimonials}/>
                </div>
            </div>
        </div>
    }
}

MainPage.contextType = AppContext;