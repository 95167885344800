import {useContext} from 'react'
import {Link} from 'react-router-dom'
import {AppContext} from "../../store/context";


export const Breadcrumbs = ({prefix, parts}) => {
    const {context} = useContext(AppContext);

    if (context.settings.isMobile) {
        let part = null;

        switch (parts.length) {
            case 0:
            case 1:
                part = {title: 'Главная', slug: '/', isMain: true};
                break;
            case 2:
                part = parts[0];
                break;
            default:
                part = parts[parts.length - 2];
                break;
        }


        return part ? <div className="breadcrumbs">

            <div className="breadcrumbs-item">
                <div className="breadcrumbs-arrow">
                    <svg width="40" height="26" viewBox="0 0 40 26" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.298288 12.1752C0.283376 12.1898 0.268464 12.2044 0.268464 12.219C0.253549 12.2336 0.238633 12.2628 0.223718 12.2774C0.208802 12.292 0.193891 12.3212 0.193891 12.3358C0.178979 12.3504 0.178975 12.365 0.16406 12.3942C0.149148 12.4088 0.149148 12.438 0.134237 12.4526C0.119321 12.4672 0.119321 12.4964 0.104406 12.511C0.0894902 12.5256 0.0894902 12.5548 0.0745747 12.5694C0.0596592 12.584 0.059663 12.6131 0.059663 12.6277C0.059663 12.6423 0.0447476 12.6715 0.0447476 12.6861C0.0447476 12.7007 0.0298359 12.7299 0.0298359 12.7445C0.0298359 12.7737 0.0149204 12.7883 0.0149204 12.8175C0.0149204 12.8321 0.0149166 12.8613 4.96236e-06 12.8759C-0.0149105 12.9635 -0.0149105 13.0511 4.93939e-06 13.1387C4.93811e-06 13.1533 4.93556e-06 13.1825 0.0149204 13.1971C0.0149204 13.2263 0.0149204 13.2409 0.0298359 13.2701C0.0298359 13.2847 0.0447475 13.3139 0.0447475 13.3285C0.0447475 13.3431 0.059663 13.3723 0.059663 13.3869C0.059663 13.4015 0.0745746 13.4307 0.0745746 13.4453C0.0745746 13.4599 0.0894901 13.4891 0.104406 13.5037C0.119321 13.5183 0.119321 13.5475 0.134236 13.5621C0.149148 13.5767 0.149148 13.6058 0.16406 13.6204C0.178975 13.635 0.178979 13.6496 0.193891 13.6788C0.208806 13.6934 0.223718 13.7226 0.223718 13.7372C0.238633 13.7518 0.253549 13.781 0.268464 13.7956C0.283376 13.8102 0.298288 13.8248 0.298288 13.8394C0.328118 13.8686 0.357946 13.8978 0.387776 13.9416L12.3192 25.6204C12.8412 26.1314 13.6913 26.1314 14.2133 25.6204C14.7353 25.1095 14.7353 24.2774 14.2133 23.7664L4.57868 14.3212L38.6577 14.3212C39.4034 14.3212 40 13.7372 40 13.0073C40 12.2774 39.4034 11.6934 38.6577 11.6934L4.57868 11.6934L14.2282 2.24819C14.7502 1.73724 14.7502 0.905122 14.2282 0.394174C13.9597 0.131401 13.6167 0.0146099 13.2737 0.0146099C12.9307 0.0146098 12.5876 0.145998 12.3192 0.394174L0.387777 12.073C0.357946 12.1022 0.328119 12.1314 0.298288 12.1752Z"/>
                    </svg>
                </div>
                <Link to={`${prefix && !part.isMain ? prefix : ''}${part.slug}`}>{part.title}</Link></div>
        </div> : null
    }

    return <div className="breadcrumbs">
        {parts.map(el => {
            return el.slug
                ?
                <div className="breadcrumbs-item">
                    <Link to={`${prefix ? prefix : ''}${el.slug}`}>{el.title}</Link></div>
                : <div className="breadcrumbs-item breadcrumbs-item--inactive">{el.title}</div>
        })}
    </div>
}