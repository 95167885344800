import {Base} from "./base.api";
import {API_HOST} from "../const";

const BASE_URL = `${API_HOST}/delivery`;

export class Delivery extends Base {
    cdekSearch(query) {
        return this.fetchJSON(`${BASE_URL}/cdek/search?query=${query}`).then(response => {
            return response.data
        })
    }

    cdekPoints(city) {
        return this.fetchJSON(`${BASE_URL}/cdek/points`, {
            method: 'POST',
            body: JSON.stringify({city: city})
        }).then(response => {
            return response.data
        })
    }


    cdekCalculate(id) {
        return this.fetchJSON(`${BASE_URL}/cdek/calculate`, {
            method: 'POST',
            body: JSON.stringify({id: id})
        }).then(response => {
            return response.data
        })
    }
}