import React, {useState} from 'react'
import {TitleWLink} from "../ui/Title";

import heart from '../../static/img/icons/heart.svg'

export const TestimonialsMainGrid = ({title, testimonials}) => {
    return <div className="testimonials-main">
        <div className="testimonials-main__title">
           <TitleWLink title={title} link={"Все отзывы"} path={"/favorite-customer-book"}/>
        </div>
        <div className="testimonials-main__grid">
            {testimonials.map(el => {
                return <TestimonialsCard item={el}/>
            })}
        </div>
    </div>
}

export const TestimonialsCard = ({item}) => {
    const [isOpen, setOpen] = useState(false);

    const texts = {
        full: item.text,
        short: item.text.substr(0, 84)
    }

    const toggleOpen = () => {
        setOpen(!isOpen)
    }


    return <div className="testimonial-card">
        <h4 className="testimonial-card__title">{item.name}</h4>
        <div className="testimonial-card__date">{item.date}</div>
        <div className="testimonial-card__content">
            {texts.full.length > 84 ?
                <>
                    <div className={`testimonial-card__text ${isOpen ? 'testimonial-card__text--show' : ''}`}>
                        {texts.full}
                    </div>
                    <div className={`testimonial-card__text testimonial-card__text--short ${!isOpen ? 'testimonial-card__text--show' : ''}`}>
                        {texts.short}
                    </div>
                    <a className="testimonial-card__text-toggle">
                        <span onClick={() => toggleOpen()}>
                            {isOpen ? 'Свернуть' : 'Читать полностью'}
                        </span>
                    </a>
                </>
                :
                <div className="testimonial-card__text testimonial-card__text--show">{texts.full}</div>
            }
        </div>
        <div className="testimonial-card__icon"><img src={heart} alt=""/></div>
    </div>
}