import {Link} from 'react-router-dom'

export const Title = ({title}) => {
    return <div className="title">
        <h2>{title}</h2>
    </div>
}

export const TitleWLink = ({title, link, path}) => {
    return <div className="title">
        <h2>{title}</h2>
        <Link to={path} className={"btn btn-white btn--small "}>{link}</Link>
    </div>
}

