import React from 'react'
import {AppContext} from "../../store/context";
import {Breadcrumbs} from "../../components/ui/Breadcrumbs";
import {Title} from "../../components/ui/Title";
import {NewsCard} from "../../components/content/News";
import {Pagination} from "../../components/ui/Pagination";
import Skeleton from 'react-loading-skeleton'

export class NewsPage extends React.Component {
    constructor() {
        super();

        this.state = {
            loaded: false,
            page: {
                current: 0,
                count: 0
            },
            news: [
                {
                    startAt: <Skeleton width={100}/>,
                    title: <Skeleton width={350}/>,
                    preview: <Skeleton count={2} width={550}/>
                },
                {
                    startAt: <Skeleton width={100}/>,
                    title: <Skeleton width={250}/>,
                    preview: <Skeleton count={3} width={550}/>
                },
                {
                    startAt: <Skeleton width={100}/>,
                    title: <Skeleton width={350}/>,
                    preview: <Skeleton count={2} width={550}/>
                },
                {
                    startAt: <Skeleton width={100}/>,
                    title: <Skeleton width={350}/>,
                    preview: <Skeleton count={2} width={550}/>
                }
            ]
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        this.context.context.processor.content.news().then(res => {
            this.setState({
                news: res.news,
                loaded: true,
            })
        })
    }

    render() {
        return <div className="news-wrapper">
            <div className="news-container container">
                <div className="news-breadcrumbs">
                    <Breadcrumbs parts={[{title: 'Главная', slug: '/'}, {title: 'Новости'}]}/>
                </div>
                <div className="news-title">
                    <Title title={'Новости'}/>
                </div>
                <div className="news-list">
                    {this.state.news.map(el => {
                        return <NewsCard item={el}/>
                    })}
                </div>
                <div className="news-pagination">
                    <Pagination current={this.state.page.current} pages={this.state.page.count}/>
                </div>
            </div>
        </div>
    }
}

NewsPage.contextType = AppContext;