export class Base {
    async checkStatus(response) {
        if (response.status >= 200 && response.status < 300) {
            return response
        }

        if (response.status === 401 || response.status === 400) {
            let json = await response.json();
            let error = new Error(json.error);
            error.code = response.status;

            throw error
        }

        if (response.status > 401) {
            let error = new Error(response.statusText);
            error.code = response.status;

            throw error
        }
    }

    fetchJSON(url, options, omitType) {
        if (options === undefined) {
            options = {};
        }

        options.credentials = 'include';

        let headers = options.headers || {};

        if (omitType !== true) {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
        }

        return fetch(url, {headers, ...options})
            .then(response => this.checkStatus(response))
            .then(response => response.json())
    }
}