import {useContext, useEffect, useState} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'

import {Button, CartButton, CatalogButton, MenuButton} from "./ui/Button";
import telegram from '../static/img/icons/socials/telegram.svg'
import whatsapp from '../static/img/icons/socials/whatsapp.svg'
import odnoklassniki from '../static/img/icons/socials/odnoklassniki.svg'
import vk from '../static/img/icons/socials/vk.svg'
import youtube from '../static/img/icons/socials/youtube.svg'
import arrow_down from '../static/img/icons/arrow-down-gray.svg'
import search from '../static/img/icons/search.svg'
import feedback from '../static/img/icons/feedback.svg'
import caret from '../static/img/icons/caret.svg'
import caretwhite from '../static/img/icons/caret-white.svg'

import cart from '../static/img/icons/cart.svg'
import profile from '../static/img/icons/profile.svg'

import logo from '../static/img/logo.svg'

import {AppContext} from "../store/context";

export const NavBar = () => {
    return <NavBarDesktop/>
};

export const NavBarSticky = () => {
    const history = useHistory()
    const {context} = useContext(AppContext)

    const count = context.cart.state.count;

    const personalButtonAction = () => {
        if (!context.user.state.customer.phone) {
            context.modal.dispatch({type: 'openModal', payload: {type: 'auth'}})
            return;
        }

        history.push('/personal')
    };

    const openSidebar = () => {
        context.modal.dispatch({type: 'openModal', payload: {type: 'sidebar'}})
    };

    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 280);
        });
    }, []);

    const submitSearch = (e) => {
        e.preventDefault();

        const s = e.target.query.value

        history.push(`/catalog/search?query=${s}`);
    }

    return <div className={`header-sticky ${scroll ? 'show' : ''}`}>
        <div className="header-sticky__wrapper">
            <div className="header-sticky__container container">
                <div className="header-sticky__grid">
                    <div className="header-sticky__menu mobile">
                        <MenuButton onClick={() => {
                            openSidebar()
                        }} className={'btn-orange'}/>
                    </div>
                    <div className="header-sticky__logo">
                        <Link to={"/"}>
                            <img src={logo} alt="Универсад"/>
                        </Link>
                    </div>
                    <div className="header-sticky__catalog">
                        <CatalogButton
                            type={"topMenuSticky"}
                            className={'btn-orange'}
                            icon={caretwhite}
                            text={'Каталог товаров'}/>
                    </div>
                    <form className="header-sticky__search" action="/catalog/search" onSubmit={submitSearch}>
                        <span className="header__search-warning">Введите минимум 3&nbsp;символа</span>
                        <input type="text" name="query" placeholder="Поиск товаров"
                               className="header__search-input"/>
                        <button type="submit" className="header__search-button"><img src={search} alt=""/></button>
                    </form>
                    <div className="header-sticky__buttons">
                        <Button onClick={() => {
                            personalButtonAction()
                        }} className={'btn-green--outline'} icon={profile}/>
                        <CartButton count={count} onClick={() => {
                            history.push('/cart')
                        }} className={'btn-green--filled'} icon={cart}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
}


const NavBarTop = () => {
    const {context} = useContext(AppContext);
    const [open, setOpen] = useState(false)


    const selectCity = (id) => {
        context.processor.user.selectCity(id).then(() => {
            window.location.reload()
        })
    };

    const confirmCity = (id) => {
        context.processor.user.selectCity(id);

        context.user.dispatch({type: 'dontAsk'});
    };

    const toggleSelector = () => {
        setOpen(!open)
    };

    return <div className="header-top__wrapper">
        <div className="header-top__container container">
            <div className="header-top__grid">
                <div className="header-top__city">
                    <div className="header-top__city-label">Ваш город:</div>
                    <div className="header-top__city-value" onClick={() => {
                        toggleSelector()
                    }}>
                        <span
                            className={"header-top__city-value--text black stroke"}>{context.user.state.city.title}</span>
                        <span className={"header-top__city-value--icon"}><img src={caret} alt=""/></span>
                    </div>
                    <div
                        className={`header-top__city-value--popup ${!open && context.user.state.askCity ? 'active' : ''}`}>
                        <div className={"text"}>Ваш город <span>{context.user.state.city.title}</span>?</div>
                        <div className="header-top__city-value--popup_buttons">
                            <div className="btn btn-orange" onClick={() => {
                                confirmCity(context.user.state.city.id)
                            }}>Да, верно
                            </div>
                            <div className="btn btn-orange--outline" onClick={() => {
                                toggleSelector()
                            }}>Выбрать другой
                            </div>
                        </div>
                    </div>
                    <div className={`header-top__city-value--selector ${open ? 'active' : ''}`}>
                        <div className="header-top__city-value--selector-box">
                            <ul className="header-top__city-value--selector-list">
                                {context.user.state.cities.map(el => {
                                    return <li className="header-top__city-value--selector-item" onClick={() => {
                                        selectCity(el.id)
                                    }}>{el.title}</li>
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="header-top__phone black stroke">
                    <a href="tel:8 800 250-08-99">
                        8 (800) 250-08-99
                    </a>
                </div>
                <div className="header-top__social">
                    <ul className="header-top__social-list">
                        <li className="header-top__social-item">
                            <a href="https://api.whatsapp.com/send?phone=79841995050&amp;text=" title="whats app"
                               target="_blank">
                                <img src={whatsapp} alt="whats app"/>
                            </a>
                        </li>
                        <li className="header-top__social-item">
                            <a href="https://ok.ru/universad" title="Одноклассники" target="_blank">
                                <img src={odnoklassniki} alt="Одноклассники"/>
                            </a>
                        </li>
                        <li className="header-top__social-item">
                            <a href="https://vk.com/universad1" title="VK" target="_blank">
                                <img src={vk} alt="VK"/>
                            </a>
                        </li>

                        <li className="header-top__social-item">
                            <a href="https://www.youtube.com/channel/UCM7XQhbs30IzfGOryj2RgdA" title="Youtube"
                               target="_blank">
                                <img src={youtube} alt="Youtube"/>
                            </a>
                        </li>
                        <li className="header-top__social-item">
                            <a href="http://t.me/universadshop" title="Telegram"
                               target="_blank">
                                <img src={telegram} alt="Telegram"/>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="header-top__feedback" onClick={() => {
                    context.modal.dispatch({type: "openModal", payload: {type: 'feedback'}})
                }}>
                    <a className="header-top__feedback-button">
                        <img src={feedback}/>
                        <span className="bold stroke">Помогите нам стать лучше</span>
                    </a>
                </div>
            </div>

        </div>
    </div>
}

const NavBarMain = () => {
    const history = useHistory()
    const {context} = useContext(AppContext)

    const count = context.cart.state.count;

    const personalButtonAction = () => {
        if (!context.user.state.customer.phone) {
            context.modal.dispatch({type: 'openModal', payload: {type: 'auth'}})
            return;
        }

        history.push('/personal')
    }

    const submitSearch = (e) => {
        e.preventDefault();

        const query = e.target.query.value

        history.push(`/catalog/search?query=${query}`);
    }

    return <div className="header__wrapper">
        <div className="header__container container">
            <div className="header__grid">
                <div className="header__logo">
                    <Link to={"/"}>
                        <img src={logo} alt="Универсад"/>
                    </Link>
                </div>
                <div className="header__catalog">
                </div>
                <form className="header__search" action="/catalog/search" onSubmit={submitSearch}>
                    <span className="header__search-warning">Введите минимум 3&nbsp;символа</span>
                    <input type="text" name="query" placeholder="Поиск товаров" className="header__search-input"/>
                    <button type="submit" className="header__search-button"><img src={search} alt=""/></button>
                </form>
                <div className="header__buttons">
                    <Button onClick={() => {
                        personalButtonAction()
                    }} className={'btn-green--outline'} icon={profile} text={'Личный кабинет'}/>
                    <CartButton count={count} onClick={() => {
                        history.push('/cart')
                    }} className={'btn-green--filled'} icon={cart} text={'Корзина'}/>
                </div>
            </div>
        </div>
    </div>
}

const NavBarMenu = () => {
    const {context} = useContext(AppContext);
    const {pathname} = useLocation();

    const [max, setMax] = useState(15);
    const [open, setOpen] = useState(false);

    return <div className="header-menu__wrapper">
        <div className="header-menu__container container">
            {pathname !== '/'
                ? <CatalogButton
                                type={"topMenuHeader"}
                                 className={'btn-orange'}
                                 icon={caretwhite}
                                 text={'Каталог товаров'}
                /> : null}
            <ul className="header-menu__list">
                {context.catalog.state.navigation.slice(0, max).map(el => {
                    return el.internal
                        ? <li className="header-menu__item">
                            <Link to={el.link}>{el.label}</Link>
                        </li>
                        : <li className="header-menu__item">
                            <a href={el.link} target={"_blank"}>{el.label}</a>
                        </li>
                })}
                {context.catalog.state.navigation.length > max ?
                    <div className="header-menu__list-more" onClick={() => {setOpen(!open)}}>
                        <span>Еще <img src={arrow_down} alt=""/></span>
                        <div className={`header-menu__list-more--selector ${open ? 'active' : ''}`}>
                            <div className="header-menu__list-more--selector-box">
                                <ul className="header-menu__list-more--selector-list">
                                    {context.catalog.state.navigation.slice(max).map(el => {
                                        return el.internal
                                            ? <li className="header-menu__item">
                                                <Link to={el.link}>{el.label}</Link>
                                            </li>
                                            : <li className="header-menu__item">
                                                <a href={el.link} target={"_blank"}>{el.label}</a>
                                            </li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    : null}
            </ul>
        </div>
    </div>
}

const NavBarDesktop = () => {
    return <div className="header">
        <NavBarTop/>
        <NavBarMain/>
        <NavBarMenu/>
    </div>
}

