import React from "react";
import Skeleton from 'react-loading-skeleton'

import {AppContext} from "../../store/context";
import {Breadcrumbs} from "../../components/ui/Breadcrumbs";
import {Title} from "../../components/ui/Title";
import {Map, Placemark, YMaps, Clusterer} from "react-yandex-maps";


export class ContactsPage extends React.Component {
    constructor() {
        super()

        this.state = {
            loaded: false,
            shops: [
                {
                    title: <Skeleton width={180}/>,
                    address: <Skeleton width={200}/>,
                    description: <Skeleton count={3} width={240}/>
                },
                {
                    title: <Skeleton width={180}/>,
                    address: <Skeleton width={200}/>,
                    description: <Skeleton count={3} width={240}/>
                },
                {
                    title: <Skeleton width={180}/>,
                    address: <Skeleton width={200}/>,
                    description: <Skeleton count={3} width={240}/>
                },
                {
                    title: <Skeleton width={180}/>,
                    address: <Skeleton width={200}/>,
                    description: <Skeleton count={3} width={240}/>
                },
                {
                    title: <Skeleton width={180}/>,
                    address: <Skeleton width={200}/>,
                    description: <Skeleton count={3} width={240}/>
                },
                {
                    title: <Skeleton width={180}/>,
                    address: <Skeleton width={200}/>,
                    description: <Skeleton count={3} width={240}/>
                },
            ]
        }
    }

    componentDidMount() {
        this.context.context.processor.content.shops().then(res => {
            this.setState({
                loaded: true,
                shops: res.shops
            })
        })
    }

    render() {
        return <div className="contacts-wrapper">
            <div className="contacts-container container">
                <div className="contacts-breadcrumbs">
                    <Breadcrumbs parts={[{title: 'Главная', slug: '/'}, {title: 'Наши магазины'}]}/>
                </div>
                <div className="contacts-title">
                    <Title title={'Наши магазины'}/>
                </div>
                <div className="contacts-map">
                    <YMaps>
                        <Map className={"contacts-map"} defaultState={{
                            center: [46.112012, 131.900687],
                            zoom: 5,
                            width: '100%',
                            height: '100%',
                        }}>
                            <Clusterer>
                                {this.state.loaded && this.state.shops.map(el => <Placemark
                                    modules={[
                                        "geoObject.addon.balloon",
                                        "geoObject.addon.hint"
                                    ]}
                                    geometry={[el.coordinates.latitude, el.coordinates.longitude]}
                                    properties={{
                                        balloonContentHeader: el.title,
                                        balloonContent: el.address,
                                    }}
                                    options={{
                                        placemarkConfig: {
                                            balloonShadow: false,
                                            balloonOffset: [3, -50],
                                            balloonPanelMaxMapArea: 0,
                                            hideIconOnBalloonOpen: false,
                                        },
                                        iconLayout:'islands#icon',
                                        iconColor:'blue',
                                    }}
                                />)}
                            </Clusterer>
                        </Map>
                    </YMaps>
                </div>

                <div className="contacts-list">
                    {this.state.shops.map(el => {
                        return <div className="contacts-item">
                            <ShopCard item={el}/>
                        </div>
                    })}
                </div>
            </div>
        </div>
    }
}

const ShopCard = ({item}) => {
    return <div className="shop-card">
        <div className="shop-card__title">{item.title}</div>
        <div className="shop-card__address">{item.address}</div>
        {typeof item.description === 'object'
            ? <div className="shop-card__description">{item.description}</div>
            : <div className="shop-card__description" dangerouslySetInnerHTML={{__html: item.description}}/>}

    </div>
}

ContactsPage.contextType = AppContext;