import {Link} from 'react-router-dom'
import {STATIC_HOST} from "../../const";
import {Loader} from "../Loader";
import placeholder from '../../static/img/photo-placeholder.png'

export const NewsCard = ({item}) => {
    return <div className="news-card">
        {typeof item.title !== 'object'
            ? <div className="news-card__image"
                   style={{backgroundImage: `url(${item.image ? STATIC_HOST + 'articles/' + item.image : placeholder})`}}/>
            : <div className="news-card__image"><Loader/></div>
        }
        <div className="news-card__content">
            <div className="news-card__date">{item.startAt}</div>
            <h4 className={'news-card__title'}><Link to={`/news/${item.slug}`}>{item.title}</Link></h4>
            <div className="news-card__preview">{item.preview}</div>
        </div>
    </div>
};