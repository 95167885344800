import {useContext, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'

import {AppContext} from "../../store/context";
import {STATIC_HOST} from "../../const";

import caret from '../../static/img/icons/menu-caret.svg'
import phone from '../../static/img/icons/phone_green.svg'
import {Button} from "../ui/Button";


export const Menu = () => {
    const {context} = useContext(AppContext);

    return <div className={'catalog-menu'}>
        <ul className="catalog-menu__list">
            {context.catalog.state.categories.map(el => {
                return <MenuItem item={el}/>
            })}
        </ul>
    </div>
};

const MenuItemInner = ({categories}) => {
    const {context} = useContext(AppContext);
    const handleRedirect = () => {
        context.modal.dispatch({type: 'closeModal'})
    }

    return <div className="catalog-menu__inner">
        <div className="catalog-menu__inner-container">
            {categories.map(a => {

                return a.visible ? <div className={`catalog-menu__inner-block`}>
                    <Link onClick={() => {handleRedirect()}}  to={`/catalog/${a.slug}`} className="catalog-menu__inner-heading">{a.title}</Link>
                    {a.children.length > 0 ?
                        <ul className={`catalog-menu__inner-subcategories ${a.children.length > 2 ? 'catalog-menu__inner-subcategories--2col' : ''}`}>
                            {a.children.map(b => {
                                return b.visible ? <li className={"catalog-menu__inner-subcategory"}>
                                    <Link onClick={() => {handleRedirect()}}  to={`/catalog/${a.slug}/${b.slug}`}>{b.title}</Link>
                                    {b.children.length > 0 ?
                                        <div className="catalog-menu__inner-last">
                                            <ul className="catalog-menu__inner-last-items">
                                                {b.children.map(c => {
                                                    return c.visible ? <li>
                                                        <Link onClick={() => {handleRedirect()}}  to={`/catalog/${a.slug}/${b.slug}/${c.slug}`}>
                                                            {c.title}
                                                        </Link>
                                                    </li> : null
                                                })}
                                            </ul>
                                        </div>
                                        : null}
                                </li> : null
                            })}
                        </ul>
                        : null}
                </div> : null
            })}
        </div>
    </div>
}

const MenuItem = ({item}) => {
    const {context} = useContext(AppContext);
    let canRoot = false;

    if (item.category) {
        if (item.category.children.length === 0) {
            canRoot = true
        } else {
            let hasVisible = false;
            item.category.children.forEach(el => {
                if (el.visible) {
                    hasVisible = true
                }
            });
            
            canRoot = !hasVisible
        }
    }

    const handleRedirect = () => {
        context.modal.dispatch({type: 'closeModal'})
    }

    return <li className="catalog-menu__item">
        <Link onClick={() => {handleRedirect()}} className="catalog-menu__item-content" to={canRoot ? `/catalog/${item.category.slug}` : '#'}>
            <div className="catalog-menu__item-icon">
                <img src={item.category ? STATIC_HOST + 'icons/' + item.icon : item.icon} alt=""/>
            </div>
            <div className="catalog-menu__item-text">{item.title}</div>
            <div className="catalog-menu__item-arrow">
                {!canRoot ? <img src={caret} alt=""/> : null}
            </div>
        </Link>
        {item.category !== null && item.category.children.length > 0 && !canRoot ?
            <div className="catalog-menu__item-inner">
                <MenuItemInner categories={item.category.children}/>
            </div> : null}
    </li>
}

export const SidebarMenu = () => {
    const {context} = useContext(AppContext);
    const history = useHistory();

    const [selected, setSelected] = useState({prev: null, items: []});

    const handleRedirect = (slug) => {
        context.modal.dispatch({type: 'closeModal'})

        history.push(slug)
    }

    return <>
        <div className="sidebar-navigation">
            {selected.items.length > 0 ? <div className="sidebar-back">
                    <Button icon={caret} text={'Назад'} className={'btn-transparent'} onClick={() => {
                        const prev = selected.prev;

                        if (prev === null) {
                            setSelected({prev: null, items: []});
                            return;
                        }

                        setSelected({prev: prev.prev, items: prev.items})
                    }}/>
                </div>
                : <div className="sidebar-phone">
                    <img src={phone} alt=""/>
                    <a href="tel:8 800 250-08-99">
                        8 (800) 250-08-99
                    </a>
                </div>}
        </div>
        <div className="sidebar-catalog">
            <div className={'mobile-menu'}>
                <ul className="mobile-menu__list">
                    {selected.items.length
                        ? selected.items.map(item => (
                            item.children.length > 0
                                ? <div className="mobile-menu__inner">
                                    <a href={"#"} className="mobile-menu__inner-heading" onClick={(e) => {
                                        e.preventDefault();

                                        if (item.children.length > 0) {
                                            setSelected({prev: selected, items: item.children})
                                        }
                                    }}>{item.title}</a>
                                    <div className="catalog-menu__item-arrow">
                                        <img src={caret} alt=""/>
                                    </div>
                                </div>
                                : <div className="mobile-menu__inner">
                                    <a href={"#"} onClick={() => {
                                        handleRedirect(`/catalog/${item.slug}`)
                                    }}
                                       className="mobile-menu__inner-heading">{item.title}</a>
                                </div>
                        ))
                        : context.catalog.state.categories.map(item => {
                            return <li className="catalog-menu__item" onClick={() => {
                                if (item.category !== null && item.category.children.length > 0) {
                                    setSelected({prev: null, items: item.category.children})
                                }
                            }}>
                                <a className="catalog-menu__item-content">
                                    <div className="catalog-menu__item-icon">
                                        <img src={item.category ? STATIC_HOST + 'icons/' + item.icon : item.icon}
                                             alt=""/>
                                    </div>
                                    <div className="catalog-menu__item-text">{item.title}</div>
                                    <div className="catalog-menu__item-arrow">
                                        <img src={caret} alt=""/>
                                    </div>
                                </a>
                            </li>
                        })}
                </ul>
            </div>
        </div>

    </>
}