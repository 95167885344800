import {useContext} from 'react'
import {AppContext} from "../../store/context";
import {Button} from "../ui/Button";
import close from '../../static/img/icons/close.svg'

export const ModalContainer = ({children}) => {
    return <div className="modal-container">{children}</div>
}
export const ModalOverlay = ({children}) => {
    const {context} = useContext(AppContext);

    const closeModal = (e) => {
        if (e.target.classList.contains('modal-overlay')) {
            context.modal.dispatch({type: 'closeModal'})
        }
    }

    return <div onClick={(e) => {
        closeModal(e)
    }}
                className={`modal-overlay ${context.modal.state.open
                && !context.modal.state.type.includes('topMenu')
                    ? 'modal-overlay--active' : ''}`}>
        {children}
    </div>
}

export const Modal = ({className, children}) => {
    const {context} = useContext(AppContext);

    return <div className={`modal ${className}`}>
        <div className="modal-close" onClick={() => {
            context.modal.dispatch({type: 'closeModal'})
        }}>
            <Button className={"btn-transparent btn-icon-only"} icon={close}/>
        </div>
        <div className="modal-body">
            {children}
        </div>
    </div>
}