import {Base} from "./base.api";
import {API_HOST} from "../const";

const BASE_URL = `${API_HOST}/cart`;

export class Cart extends Base {
    cart() {
        return this.fetchJSON(`${BASE_URL}/`).then(response => {
            return response.data
        })
    }

    cartExtended() {
        return this.fetchJSON(`${BASE_URL}/extended`).then(response => {
            return response.data
        })
    }


    addProduct(id) {
        return this.fetchJSON(`${BASE_URL}/product/add`, {
            method: 'POST',
            body: JSON.stringify({id : id})
        }).then(response => {
            return response.data
        })
    }

    removeProduct(id) {
        return this.fetchJSON(`${BASE_URL}/product/remove`, {
            method: 'POST',
            body: JSON.stringify({id: id})
        }).then(response => {
            return response.data
        })
    }

    clearProduct(id) {
        return this.fetchJSON(`${BASE_URL}/product/clear`, {
            method: 'POST',
            body: JSON.stringify({id: id})
        }).then(response => {
            return response.data
        })
    }


    preCheckout(data) {
        return this.fetchJSON(`${BASE_URL}/checkout/prepare`, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then(response => {
            return response.data
        });
    }

    calculateBonuses(data) {
        return this.fetchJSON(`${BASE_URL}/checkout/calculate`, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then(response => {
            return response.data
        });
    }


    checkout(data) {
        return this.fetchJSON(`${BASE_URL}/checkout`, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then(response => {
            return response.data
        });
    }
}