import {useContext, useRef, useState} from 'react'
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination,} from 'swiper'

import {Button} from "./ui/Button";
import {ProductCard} from "./catalog/Product";
import {NewsCard} from "./content/News";
import {Title, TitleWLink} from "./ui/Title";
import {Banner} from "./content/Banner";


import arrow_right from '../static/img/icons/angle-right-thin.svg'
import arrow_left from '../static/img/icons/angle-left-thin.svg'
import placeholder from '../static/img/photo-placeholder.png'

import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {AppContext} from "../store/context";
import {STATIC_HOST} from "../const";


export const BannerSlider = ({slides}) => {
    const params = {
        className: "banner-slider",
        modules: [Navigation, Pagination],
        spaceBetween: 16,
        pagination: {clickable: true},
        freeMode: true,
        breakpoints: {
            640: {
                slidesPerView: 1,
                freeMode: false
            }
        },
    };

    return <Swiper {...params}>
        {slides.map(el => {
            return <SwiperSlide>
                <Banner item={el}/>
            </SwiperSlide>
        })}
    </Swiper>
};

export const ProductSliderSingle = ({title, products}) => {
    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);

    const params = {
        slidesPerView: 4,
        spaceBetween: 16,
        navigation: {
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
        },
        onBeforeInit: (swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
        },
        className: "product-slider product-slider--single",
        modules: [Navigation],
        pagination: {clickable: true},
        watchOverflow: true,
        breakpoints: {
            900: {
                allowTouchMove: false,
            },
        },
    };

    return <div className="product-slider">
        <div className="product-slider__title">
            <Title title={title}/>
            <div className="product-slider__title-nav">
                <div ref={navigationPrevRef}>
                    <Button className={'btn-transparent btn-icon-only'} icon={arrow_left}/>
                </div>
                <div ref={navigationNextRef}>
                    <Button className={'btn-transparent btn-icon-only'} icon={arrow_right}/>
                </div>
            </div>
        </div>
        <Swiper {...params}>
            {products.map(el => {
                return <SwiperSlide className={"product-slider__slider-item"}>
                    <ProductCard product={el}/>
                </SwiperSlide>
            })}
        </Swiper>
    </div>
}

export const ProductSliderMultiple = ({title, products}) => {
    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null)

    const {context} = useContext(AppContext)

    const params = {
        className: "product-slider__slider product-slider__slider--multiple",
        modules: [Navigation],
        pagination: {clickable: true},
        navigation: {
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
        },

        onBeforeInit: (swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
        },
        slidesPerView: 2,
        spaceBetween: 16,
        breakpoints: {
            900: {
                slidesPerView: 3,
            },
            1200: {
                slidesPerView: 4,
            },
        },
    };

    const chunks = products.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 2)

        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
    }, []);

    return <div className="product-slider">
        <div className="product-slider__title">
            <Title title={title}/>
            <div className="swiper-pagination"/>
            <div className="product-slider__title-nav">
                <div ref={navigationPrevRef}>
                    <Button className={'btn-transparent btn-icon-only'} icon={arrow_left}/>
                </div>
                <div ref={navigationNextRef}>
                    <Button className={'btn-transparent btn-icon-only'} icon={arrow_right}/>
                </div>
            </div>
        </div>
        <Swiper {...params}>
            {context.settings.isMobile
                ? products.map(el => {
                    return <SwiperSlide className={"product-slider__slider-item"}>
                        <ProductCard product={el}/>
                    </SwiperSlide>
                })
                : chunks.map(el => {
                    return <SwiperSlide className={"product-slider__slider-item"}>
                        {el.length === 2 ?
                            <>
                                <ProductCard product={el[0]}/>
                                <ProductCard product={el[1]}/>
                            </>
                            :
                            <ProductCard product={el[0]}/>
                        }
                    </SwiperSlide>
                })}
        </Swiper>
    </div>
};

export const NewsSlider = ({title, news}) => {
    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);

    const params = {
        navigation: {
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
        },
        onBeforeInit: (swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
        },
        className: "product-slider product-slider--single",
        modules: [Navigation],
        pagination: {clickable: true},
        watchOverflow: true,
        slidesPerView: 1,
        spaceBetween: 48,
        breakpoints: {
            640: {
                slidesPerView: 2,
                allowTouchMove: false,
            },
        },
    };

    return <div className="news-slider">
        <div className="news-slider__title">
            <TitleWLink title={title} link="Все новости" path={"/news"}/>
            <div className="news-slider__title-nav">
                <div ref={navigationPrevRef}>
                    <Button className={'btn-transparent btn-icon-only'} icon={arrow_left}/>
                </div>
                <div ref={navigationNextRef}>
                    <Button className={'btn-transparent btn-icon-only'} icon={arrow_right}/>
                </div>
            </div>
        </div>
        <Swiper {...params}>
            {news.map(el => {
                return <SwiperSlide className={"news-slider__slider-item"}>
                    <NewsCard item={el}/>
                </SwiperSlide>
            })}
        </Swiper>
    </div>
}

export const ProductImagesSlider = ({images}) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    const image = images && images.length > 0 ? STATIC_HOST + 'products/' + images[0].source : placeholder;

    return <img className={'product-gallery__image'} src={image} alt=""/>
}
