import {useContext, useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import InputMask from 'react-input-mask';

import {Modal} from "./Modal";
import {Button} from "../ui/Button";
import {AppContext} from "../../store/context";

export const AuthModal = () => {
    const {context} = useContext(AppContext);

    const [step, setStep] = useState(1);
    const [data, setData] = useState({phone: null});
    const [inProgress, setInProgress] = useState(false);

    const isOpen = () => {
        return context.modal.state.open && context.modal.state.type === 'auth'
    };

    const onPhoneSend = (data) => {
        setData({phone: data.phone});
        setStep(2)
    }

    useEffect(() => {
        if (isOpen()) {
            setStep(1);
            setData({phone: null});
        }
    }, [context.modal.state.open]);


    return <Modal className={`auth-modal ${isOpen() ? 'open' : ''}`}>
        <div className="auth-modal__title">Вход</div>
        <div className="auth-modal__body">
            {step === 1
                ? <AuthPhone callback={onPhoneSend}/>
                : <AuthCode phone={data.phone}/>}
        </div>
    </Modal>
}

const AuthPhone = ({callback}) => {
    const {context} = useContext(AppContext);
    const [data, setData] = useState({phone: null});

    const handlePhone = (e) => {
        setData({...data, phone: e.currentTarget.value})
    };

    const sendPhone = () => {
        if (data.phone.length < 11) {
            context.toast.error('Пожалуйста, проверьте корректность введенного номера телефона');
            return;
        }

        const phoneUnmasked = data.phone.match(/\d+/g).join('');

        if (phoneUnmasked.length < 11) {
            context.toast.error('Пожалуйста, проверьте корректность введенного номера телефона');
            return;
        }

        context.processor.user.sendCode(phoneUnmasked).then(res => {
            callback(data);
        }).catch(err => {
            context.toast.error(err.message)
        })
    }

    return <>
        <div className="auth-modal__subtitle">Для того чтобы войти или зарегистрироваться, введите свой
            номер телефона
        </div>
        <div className="auth-modal__form form">
            <div className="form-field">
                <InputMask className={'form-field__input'} value={data.phone}
                           placeholder={'+7 (___) ___ __ __'}
                           mask="+7 (999) 999 99 99"
                           maskChar="_" onChange={(e) => {
                    handlePhone(e)
                }}/>
            </div>
        </div>
        <div className="auth-modal__button">
            <Button onClick={() => {
                sendPhone()
            }} className={"btn-green--outline"} text={"Отправить код"}/>
        </div>
        <div className="auth-modal__disclamer">
            Входя на сайт, вы соглашаетесь с обработкой персональных данных, <Link to={'/privacy'}>политикой
            конфиденциальности</Link> и <Link to={'/eula'}> пользовательским соглашением</Link>.
        </div>
    </>
}

const AuthCode = ({callback, phone}) => {
    const {context} = useContext(AppContext);
    const history = useHistory();

    const [otp, setOtp] = useState(null);

    const verifyCode = () => {
        if (phone.length < 11) {
            context.toast.error('Пожалуйста, проверьте корректность введенного номера телефона');
            return;
        }

        if (otp.length < 4) {
            context.toast.error('Пожалуйста, проверьте корректность введенного кода');
            return;
        }

        const phoneUnmasked = phone.match(/\d+/g).join('');

        if (phoneUnmasked.length < 11) {
            context.toast.error('Пожалуйста, проверьте корректность введенного номера телефона');
            return;
        }

        const codeUnmasked = otp.match(/\d+/g).join('');

        if (codeUnmasked.length < 4) {
            context.toast.error('Пожалуйста, проверьте корректность введенного номера телефона');
            return;
        }

        context.processor.user.validateCode(phoneUnmasked, codeUnmasked).then(res => {
            context.user.dispatch({type: "auth", payload: res.customer})
            context.modal.dispatch({type: 'closeModal'})
            history.push("/personal")
        }).catch(err => {
            context.toast.error(err.message)
        })
    };

    return <>
        <div className="auth-modal__subtitle">Мы отправили код подтверждения на номер <br/> {phone}</div>
        <div className="auth-modal__form form">
            <div className="form-field">
                <InputMask style={{textAlign: "center"}} className={'form-field__input'} value={otp}
                           placeholder={'_ _ _ _'}
                           mask="9 9 9 9"
                           maskChar="_" onChange={(e) => {
                    setOtp(e.currentTarget.value)
                }}/>
            </div>
        </div>
        <div className="auth-modal__button">
            <Button onClick={() => {
                verifyCode()
            }} className={"btn-green--outline"} text={"Войти"}/>
        </div>
    </>
}