import notfound from '../static/img/not-found.svg'

export const NotFound = () => {
    return <section className="not-found">
        <div className="not-found__content">
            <div className="not-found__image">
                <img src={notfound} alt=""/>
            </div>
            <h2 className="not-found__title">Эта страница уехала на дачу</h2>
            <h3 className="not-found__subtitle">или ее не существует</h3>
        </div>
    </section>
}