import React from 'react'
import {NotFound} from "../components/NotFound";
import {AppContext} from "../store/context";
import {LoaderFullsize} from "../components/Loader";
import {Breadcrumbs} from "../components/ui/Breadcrumbs";
import {STATIC_HOST} from "../const";

export class CustomPage extends React.Component {
    constructor() {
        super();

        this.state = {
            page: null,
            loaded: false
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        const path = this.props.match.params[0];

        this.context.context.processor.pages.page(path).then(res => {
            this.setState({page: res.page, loaded: true});
        }).catch(err => {
            this.setState({page: null, loaded: true});
        })
    }

    componentDidUpdate(prevProps) {
        const path = this.props.match.params[0];
        const prevPath = prevProps.match.params[0];

        if (path !== prevPath) {
            this.setState({page: null, loaded: false});
            this.componentDidMount()
        }
    }

    render() {
        return this.state.loaded ?
            this.state.page != null
                ? <RenderedPage page={this.state.page}/>
                : <NotFound/>
            : <LoaderFullsize/>
    }
}

const RenderedPage = ({page}) => {
    return <div className={`page-container container ${page.layout === 1 ?'page-container-fluid': ''}`}>
        <Breadcrumbs parts={[{slug: '/', title: 'Главная'}, {title: page.title}]}/>
        <div className="page-title">
            <h2 className="title">{page.title}</h2>
        </div>
        {page.image ?
            <div className="page-image">
                <img src={STATIC_HOST + 'pages/' + page.image} alt=""/>
            </div> : null}
        <div className={'page-content'} dangerouslySetInnerHTML={{__html: page.text}}/>
    </div>
};

CustomPage.contextType = AppContext