import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Main} from "../layouts/Main";
import {CustomPage} from '../views/CustomPage'
import routes from "./index";

const Routes = () => {
    return <Router>
            <Switch>
                {RouteMap()}
                <Route path={'/*'} exact render={(props) => (
                    <Main>
                        <CustomPage {...props}/>
                    </Main>
                )}/>
            </Switch>
        </Router>
};

const RouteMap = () => {
    let map = [];

    routes.forEach((el, index) => {
        map.push(componentRoute(el, index));
    });

    return map
};

const componentRoute = ({layout: Layout, component: Component, path}, index) => {
    return Component ? (<Route key={index} path={path} exact render={(props) => (
        <Layout><Component {...props} /></Layout>
    )}/>) : null;
};

export default Routes;
