import {Base} from "./base.api";
import {API_HOST} from "../const";

const BASE_URL = `${API_HOST}/catalog`;

export class Catalog extends Base {
    category(category, page, sort, dir) {
        page = page ? parseInt(page) : 1;
        page = !isNaN(page) ? page : 1;

        return this.fetchJSON(`${BASE_URL}/${category}?page=${page}&sort=${sort}&direction=${dir}`).then(response => {
            return response.data
        })
    }

    search(query, page) {
        page = page ? parseInt(page) : 1;
        page = !isNaN(page) ? page : 1;

        return this.fetchJSON(`${BASE_URL}/search?page=${page}&query=${query}`).then(response => {
            return response.data
        })
    }

    product(product) {
        return this.fetchJSON(`${BASE_URL}/product/${product}`).then(response => {
            return response.data
        })
    }
}