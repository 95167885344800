import React from "react";
import {ToastContainer} from 'react-toastify';

import {NavBar, NavBarSticky} from "../components/NavBar"
import {Footer} from "../components/Footer"
import {ModalOverlay} from "../components/modal/Modal";
import {AuthModal} from "../components/modal/AuthModal";
import {FeedbackModal} from '../components/modal/FeedbackModal'
import {CheckoutModal} from "../components/modal/CheckoutModal";
import {Sidebar} from "../components/Sidebar";
import * as Jivosite from 'react-jivosite'
import {usePageViews} from "../App";

import 'react-toastify/dist/ReactToastify.css';

const Wrapper = ({className, children}) => (
    <div className={`main ${className}`}>{children}</div>
);

export const Main = ({children}) => {
    usePageViews();

    return <React.Fragment>
        <Sidebar/>
        <NavBarSticky/>
        <NavBar/>
        <Wrapper className="">
            {children}
        </Wrapper>
        <Footer/>
        <ModalOverlay>
            <AuthModal/>
            <FeedbackModal/>
            <CheckoutModal/>
        </ModalOverlay>
        <Jivosite.Widget id="ZTL0HT0hMa"/>
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </React.Fragment>
};
