import {STATIC_HOST} from "../../const";
import {Button} from "./../ui/Button";
import {Loader} from "../Loader";

export const Banner = ({item}) => {
    return <div className={"banner-slider__item"} style={{backgroundImage: `url(${STATIC_HOST + 'bb/' + item.image})`}}>
        {item.title != null && typeof item.title === 'object' ? <Loader/> :
        <a target={'_blank'} href={item.link} className={"banner-slider__item-link"}>
            <div className="banner-slider__item-content">
                <div className="banner-slider__item-title">{item.title}</div>
                <div className="banner-slider__item-text">{item.text}</div>
                <Button className={'btn-white'} text={"Подробнее"}/>
            </div>
        </a>}
    </div>
}