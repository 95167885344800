import React from 'react'
import {NotFound} from "../../../components/NotFound";
import {AppContext} from "../../../store/context";
import {LoaderFullsize} from "../../../components/Loader";
import {Breadcrumbs} from "../../../components/ui/Breadcrumbs";
import {STATIC_HOST} from "../../../const";

export class ClubArticle extends React.Component {
    constructor() {
        super();

        this.state = {
            article: null,
            loaded: false
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        const path = this.props.match.params[0];

        this.context.context.processor.content.clubArticle(path).then(res => {
            this.setState({article: res.article, loaded: true});
        }).catch(err => {
            this.setState({article: null, loaded: true});
        })
    }

    componentDidUpdate(prevProps) {
        const path = this.props.match.params[0];
        const prevPath = prevProps.match.params[0];

        if (path !== prevPath) {
            this.setState({article: null, loaded: false});
            this.componentDidMount()
        }
    }

    render() {
        return this.state.loaded ?
            this.state.article != null
                ? <RenderedArticle page={this.state.article}/>
                : <NotFound/>
            : <LoaderFullsize/>
    }
}

const RenderedArticle = ({page}) => {
    return <div className="page-container container">
        <Breadcrumbs parts={[
            {slug: '/', title: 'Главная'},
            {slug: '/club', title: 'Клуб "Универсадоводы"'},
            {title: page.title}
        ]}/>
        <div className="page-title">
            <h2 className="title">{page.title}</h2>
        </div>
        {page.image ?
            <div className="page-image">
                <img src={STATIC_HOST + 'articles/' +  page.image} alt=""/>
            </div> : null}
        <div className={'page-content'} dangerouslySetInnerHTML={{__html: page.text}}/>
    </div>
};

ClubArticle.contextType = AppContext;