import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import {LazyLoadImage} from 'react-lazy-load-image-component';

import {Button} from "./../ui/Button";
import {Loader, LoaderSmall} from "../Loader";

import placeholder from '../../static/img/photo-placeholder.png'
import {AppContext} from "../../store/context";
import {Counter} from "../ui/Counter";
import {STATIC_HOST} from "../../const";

export const ProductCard = ({product}) => {
    const {context} = useContext(AppContext);

    const cartProduct = context.cart.state.products[product.id];

    const addProduct = (id) => {
        context.processor.cart.addProduct(id).then(res => {
            context.cart.dispatch({type: 'setCart', payload: res.cart})
        })
    };

    const removeProduct = (id) => {
        context.processor.cart.removeProduct(id).then(res => {
            context.cart.dispatch({type: 'setCart', payload: res.cart})
        })
    };

    const image = product.preview  ? STATIC_HOST + 'products/' + product.preview : placeholder;

    return <div className="product-card">
        <Link  to={product.slug == null ? '#' : `/product/${product.slug}`} className="product-card__link">
            <div className="product-card__header">
                <LazyLoadImage
                    effect={'opacity'}
                    alt={product.title}
                    src={image}
                    placeholder={<Loader/>}/>
            </div>
            <div className="product-card__body">
                <h3 className="product-card__title">{product.title}</h3>
            </div>
        </Link>
        <div className="product-card__footer">
            <div className="product-card__price">
                <span className="value">{product.various ? `от ${product.price}` : product.price}</span>
                <span className="currency">₽</span>
            </div>
            <div className="product-card__button">
                {typeof product.title === 'object' ? <Button className={"btn-green"} text={<LoaderSmall/>}/> :
                    cartProduct ?
                        <Counter product={cartProduct} plusCallback={addProduct} minusCallback={removeProduct}/>
                        : <Button onClick={() => {
                            addProduct(product.id)
                        }} className={"btn-green"} text={"Добавить в корзину"}/>}
            </div>
        </div>
    </div>
}