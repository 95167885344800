export const DescriptionProductIcon = () => {
    return <svg width="16" height="13" viewBox="0 0 16 13" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <g>
            <rect width="16" height="3" rx="1.5" fill="currentColor"/>
            <rect y="5" width="16" height="3" rx="1.5" fill="currentColor"/>
            <rect y="10" width="10" height="3" rx="1.5" fill="currentColor"/>
        </g>
    </svg>
}

export const TestimonialsProductIcon = () => {
    return <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path
            fill="currentColor"
            d="M2 0C0.895431 0 0 0.89543 0 2V9C0 10.1046 0.895431 11 2 11H7L12 15V11H14C15.1046 11 16 10.1046 16 9V2C16 0.895431 15.1046 0 14 0H2Z"/>
        <rect x="3" y="3" width="10" height="2" rx="1" fill="white"/>
        <rect x="3" y="6" width="8" height="2" rx="1" fill="white"/>
    </svg>
}

export const AvailabilityProductIcon = () => {
    return <svg width="16" height="14" viewBox="0 0 16 14" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.41491 0.160398C8.18515 -0.0534659 7.81485 -0.0534659 7.58509 0.160398L0.175715 7.05742C-0.1982 7.40548 0.0642006 8.00435 0.59062 8.00435H2.51619C2.84172 8.00435 3.10562 8.25224 3.10562 8.55802V13.4463C3.10562 13.7521 3.36952 14 3.69506 14H6.56857C6.89411 14 7.15801 13.7521 7.15801 13.4463V10.0271C7.15801 9.72129 7.42191 9.4734 7.74745 9.4734H8.25255C8.57809 9.4734 8.84199 9.72129 8.84199 10.0271V13.4463C8.84199 13.7521 9.10589 14 9.43143 14H12.3049C12.6305 14 12.8944 13.7521 12.8944 13.4463V8.55802C12.8944 8.25224 13.1583 8.00435 13.4838 8.00435H15.4094C15.9358 8.00435 16.1982 7.40548 15.8243 7.05742L8.41491 0.160398Z"
            fill="currentColor"/>
    </svg>
}

export const CartClearIcon = () => {
    return <svg fill="currentColor" viewBox="0 0 32 32" height="32" width="32" xmlns="http://www.w3.org/2000/svg">
        <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"
              d="M31 16c0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15C1 7.716 7.716 1 16 1c8.284 0 15 6.716 15 15zm1 0c0 8.837-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0s16 7.163 16 16zM13.118 9.855C13.488 8.615 14.572 8 15.73 8c1.157 0 2.253.616 2.642 1.855h-5.254zm-2.051 0C11.509 7.288 13.658 5.999 15.73 6c2.073 0 4.23 1.29 4.698 3.855H23a1 1 0 110 2H9a1 1 0 110-2h2.067zM8 14.92c0-.65.528-1.178 1.179-1.178H22.82a1.179 1.179 0 010 2.357H22.5l-1.242 8.202a2 2 0 01-1.978 1.7h-6.56a2 2 0 01-1.978-1.7L9.5 16.1h-.321A1.179 1.179 0 018 14.921z"/>
    </svg>
}

export const AngleLeft = () => {
    return <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 9L1 5L5 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
}

export const AngleRight = () => {
    return <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 9L5 5L1 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
}