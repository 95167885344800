import {Base} from "./base.api";
import {API_HOST} from "../const";

const BASE_URL = `${API_HOST}/screen`;

export class Screen extends Base {

    getScreen(screen) {
        return this.fetchJSON(`${BASE_URL}/${screen}`).then(response => {
            return response.data
        })
    }

    getCatalogScreen(category) {
        const url = category
            ? `${BASE_URL}/catalog?category=${category}`
            : `${BASE_URL}/catalog`;

        return this.fetchJSON(url).then(response => {
            return response.data
        })
    }
}