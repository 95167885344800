import React, {useState} from "react";
import {useHistory, useLocation} from 'react-router-dom'
import {Title} from "../../components/ui/Title";
import {Breadcrumbs} from "../../components/ui/Breadcrumbs";
import {Pagination} from "../../components/ui/Pagination";

import {ProductCard} from "../../components/catalog/Product";

import Skeleton from 'react-loading-skeleton'
import {AppContext} from "../../store/context";


const CatalogSkeleton = () => {
    return {
        items: [
            {title: <Skeleton width={120} count={2}/>, slug: null, price: <Skeleton width={60}/>},
            {title: <Skeleton width={120} count={2}/>, slug: null, price: <Skeleton width={90}/>},
            {title: <Skeleton width={120} count={2}/>, slug: null, price: <Skeleton width={60}/>},
            {title: <Skeleton width={120} count={2}/>, slug: null, price: <Skeleton width={90}/>},
            {title: <Skeleton width={120} count={1}/>, slug: null, price: <Skeleton width={90}/>},
            {title: <Skeleton width={120} count={3}/>, slug: null, price: <Skeleton width={60}/>},
            {title: <Skeleton width={120} count={2}/>, slug: null, price: <Skeleton width={60}/>},
            {title: <Skeleton width={120} count={1}/>, slug: null, price: <Skeleton width={60}/>},
        ],
        page: 1,
        category: {
            pages: null,
            title: <Skeleton width={300}/>,
            breadcrumbs: [
                {title: <Skeleton width={200}/>,},
                {title: <Skeleton width={200}/>,},
                {title: <Skeleton width={100}/>,}
            ]
        },
        loaded: false,
    }
}

export class Category extends React.Component {
    constructor() {
        super();

        this.state = {
            ...CatalogSkeleton(),
        }
    }


    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        const path = this.props.match.params[0];
        const category = path.split('/').pop();

        const query = new URLSearchParams(this.props.location.search)
        const page = query.get('page') || 1
        const sort = query.get('sort') || 'novelty';
        const dir = query.get('direction') || 'desc';

        this.context.context.processor.catalog.category(category, page, sort, dir).then(res => {
            this.setState({
                page: page,
                items: res.products,
                category: res.category,
                loaded: true,
            })
        }).catch(err => {
            window.location.replace('/404')
        })
    }

    componentDidUpdate(prevProps) {
        const path = this.props.match.params[0];
        const prevPath = prevProps.match.params[0];

        if (path !== prevPath) {
            this.setState({...CatalogSkeleton()});
            this.componentDidMount()
        }

        const prevQuery = new URLSearchParams(prevProps.location.search);
        const prevPage = prevQuery.get('page') || 1;
        const prevSort = prevQuery.get('sort') || 'novelty';
        const prevDir = prevQuery.get('direction') || 'desc';

        const query = new URLSearchParams(this.props.location.search);
        const page = query.get('page') || 1;
        const sort = query.get('sort') || 'novelty';
        const dir = query.get('direction') || 'desc';

        if (prevPage != page || prevSort != sort || prevDir != dir) {
            this.setState({items: CatalogSkeleton().items});
            this.componentDidMount()
        }
    }


    render() {
        return <div className={"category-wrapper"}>
            <div className="category-container container">
                <div className="category-breadcrumbs">
                    <Breadcrumbs
                        prefix={'/catalog/'}
                        parts={this.state.category.breadcrumbs}/>
                </div>
                <div className="category-title">
                    <Title title={this.state.category.title}/>
                    {this.state.loaded && this.state.items.length > 0 ? <CatalogSort/> : null}
                </div>
                {this.state.loaded && this.state.category.title === 'Теплицы и парники с поликарбонатом' ?
                    <div className="category-text">
                        Уважаемые покупатели! Обращаем Ваше внимание, в нашем интернет магазине представлены
                        составляющие
                        теплиц. Теплица состоит из каркаса, поликарбоната и дополнительных комплектующих. Рассчитать,
                        проконсультировать, оформить заказ на теплицу можно по ссылке <a
                        href="https://теплицы-универсад.рф" target="_blank">теплицы-универсад.рф</a> или онлайн в
                        чате!
                    </div> : null}
                <div className="category-products">
                    {!this.state.items.length ? 'К сожалению, ничего не найдено' : this.state.items.map(el => {
                        return <ProductCard product={el}/>
                    })}
                </div>
                {this.state.category.pages ?
                    <div className="category-pagination">
                        <Pagination current={this.state.page} pages={this.state.category.pages}/>
                    </div> : null}
            </div>
        </div>
    }
}

const CatalogSort = () => {
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const {pathname, search} = useLocation();

    const [title, setTitle] = useState('по новизне')

    const params = new URLSearchParams(search);
    const currSort = params.get('sort') || 'novelty'
    const currDir = params.get('direction') || 'desc'


    const changeSort = (sort) => {
        setOpen(false);

        switch (sort) {
            case 'novelty_desc':
                setTitle('по новизне')
                history.push(`${pathname}?sort=novelty&direction=desc`);
                return;
            case 'price_asc':
                setTitle('по возрастанию цены')
                history.push(`${pathname}?sort=price&direction=asc`);
                return;
            case 'price_desc':
                setTitle('по убыванию цены')
                history.push(`${pathname}?sort=price&direction=desc`);
                return;
            case 'name_asc':
                setTitle('по алфавиту (а-я)')
                history.push(`${pathname}?sort=name&direction=asc`);
                return;
            case 'name_desc':
                setTitle('по алфавиту (я-а)')
                history.push(`${pathname}?sort=name&direction=desc`);
                return;
        }
    }

    return <div className="category-sort">
        <span className="category-sort--label">Сортировать: </span>
        <span className="category-sort--value" onClick={() => {
            setOpen(!open)
        }}>{title}</span>
        <div className={`category-sort--dropdown ${open ? 'open' : ''}`}>
            <ul>
                <li className={currSort + '_' + currDir === 'novelty_desc' ? 'active' : ''} onClick={() => {
                    changeSort('novelty_desc')
                }}>
                    по новизне
                </li>
                <li className={currSort + '_' + currDir === 'name_asc' ? 'active' : ''} onClick={() => {
                    changeSort('name_asc')
                }}>
                    по алфавиту (а-я)
                </li>
                <li className={currSort + '_' + currDir === 'name_desc' ? 'active' : ''} onClick={() => {
                    changeSort('name_desc')
                }}>
                    по алфавиту (я-а)
                </li>
                <li className={currSort + '_' + currDir === 'price_asc' ? 'active' : ''} onClick={() => {
                    changeSort('price_asc')
                }}>
                    по возрастанию цены
                </li>
                <li className={currSort + '_' + currDir === 'price_desc' ? 'active' : ''} onClick={() => {
                    changeSort('price_desc')
                }}>
                    по убыванию цены
                </li>
            </ul>
        </div>
    </div>
}


Category.contextType = AppContext;