import {Base} from "./base.api";
import {API_HOST} from "../const";

const BASE_URL = `${API_HOST}/content`;

export class Content extends Base {
    news() {
        return this.fetchJSON(`${BASE_URL}/news`).then(response => {
            return response.data
        })
    }

    actions() {
        return this.fetchJSON(`${BASE_URL}/actions`).then(response => {
            return response.data
        })
    }

    shops() {
        return this.fetchJSON(`${BASE_URL}/shops`).then(response => {
            return response.data
        })
    }


    vacancies() {
        return this.fetchJSON(`${BASE_URL}/vacancies`).then(response => {
            return response.data
        })
    }

    testimonials() {
        return this.fetchJSON(`${BASE_URL}/testimonials`).then(response => {
            return response.data
        })
    }

    article(slug) {
        return this.fetchJSON(`${BASE_URL}/article?slug=${slug}`).then(response => {
            return response.data
        })
    }

    club() {
        return this.fetchJSON(`${BASE_URL}/club`).then(response => {
            return response.data
        })
    }

    clubArticle(slug) {
        return this.fetchJSON(`${BASE_URL}/club/article?slug=${slug}`).then(response => {
            return response.data
        })
    }
}