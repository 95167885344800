import React from "react";
import {AppContext} from "../../store/context";

import {Title} from "../../components/ui/Title";
import {Breadcrumbs} from "../../components/ui/Breadcrumbs";
import {Pagination} from "../../components/ui/Pagination";

import {ProductCard} from "../../components/catalog/Product";

import Skeleton from 'react-loading-skeleton'


const SearchSkeleton = () => {
    return {
        items: [
            {title: <Skeleton width={120} count={2}/>, slug: null, price: <Skeleton width={60}/>},
            {title: <Skeleton width={120} count={2}/>, slug: null, price: <Skeleton width={90}/>},
            {title: <Skeleton width={120} count={2}/>, slug: null, price: <Skeleton width={60}/>},
            {title: <Skeleton width={120} count={2}/>, slug: null, price: <Skeleton width={90}/>},
            {title: <Skeleton width={120} count={1}/>, slug: null, price: <Skeleton width={90}/>},
            {title: <Skeleton width={120} count={3}/>, slug: null, price: <Skeleton width={60}/>},
            {title: <Skeleton width={120} count={2}/>, slug: null, price: <Skeleton width={60}/>},
            {title: <Skeleton width={120} count={1}/>, slug: null, price: <Skeleton width={60}/>},
        ],
        page: 1,
        query: null,
    }
}

export class Search extends React.Component {
    constructor() {
        super();

        this.state = {
            ...SearchSkeleton(),
        }
    }


    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        const params = new URLSearchParams(this.props.location.search);
        const page = params.get('page') || 1;
        const query = params.get('query') || null;

        if (!query) {
            window.location.replace('/404');
            return;
        }

        this.setState({...this.state, query: query});

        this.context.context.processor.catalog.search(query, page).then(res => {
            this.setState({
                page: page,
                items: res.products,
            })
        }).catch(err => {
            window.location.replace('/404')
        })
    }

    componentDidUpdate(prevProps) {
        const path = this.props.match.params[0];
        const prevPath = prevProps.match.params[0];

        if (path !== prevPath) {
            this.setState({...SearchSkeleton()});
            this.componentDidMount()
        }

        const prevParams = new URLSearchParams(prevProps.location.search)
        const prevPage = prevParams.get('page') || 1
        const prevQuery = prevParams.get('query') || null


        const params = new URLSearchParams(this.props.location.search)
        const page = params.get('page') || 1
        const query = params.get('query') || null

        if (prevPage != page || prevQuery != query) {
            this.setState({items: SearchSkeleton().items});
            this.componentDidMount()
        }
    }

    render() {
        return <div className={"category-wrapper"}>
            <div className="category-container container">
                <div className="category-breadcrumbs">
                    <Breadcrumbs parts={[{title: 'Главная', slug: '/'}, {title: 'Поиск'}]}/>
                </div>
                <div className="category-title">
                    <Title title={`Поиск "${this.state.query}"`}/>
                </div>
                <div className="category-products">
                    {this.state.items.length !== 0 ?
                    this.state.items.map(el => {
                        return <ProductCard product={el}/>
                    }) : 'По вашему запросу ничего не найдено'}
                </div>
                {/*{this.state.category.pages ?*/}
                    {/*<div className="category-pagination">*/}
                        {/*<Pagination current={this.state.page} pages={this.state.category.pages}/>*/}
                    {/*</div> : null}*/}
            </div>
        </div>
    }
}

Search.contextType = AppContext;