import React, {useContext, useEffect, useState} from "react";

import {Breadcrumbs} from "../../components/ui/Breadcrumbs";
import {AppContext} from "../../store/context";
import {Button} from "./../../components/ui/Button";
import {ProductImagesSlider} from '../../components/Slider'

import {AvailabilityProductIcon, DescriptionProductIcon, TestimonialsProductIcon} from "../../components/ui/Icons";
import {pluralize} from "../../components/Helpers";

import Skeleton from 'react-loading-skeleton'
import {Counter} from "../../components/ui/Counter";
import {LoaderSmall} from '../../components/Loader'

import caret from '../../static/img/icons/caret.svg'

const SkeletonProduct = () => {
    return {
        product: {
            title: <Skeleton count={2} width={450}/>,
            description: <Skeleton count={5} width={850}/>,
            code: <Skeleton width={100}/>
        },
        price: <Skeleton width={85}/>,
        offers: [],
        shopsAvailable: 0,
        breadcrumbs: [
            {title: <Skeleton width={200}/>,},
            {title: <Skeleton width={200}/>,},
            {title: <Skeleton width={100}/>,},
            {title: <Skeleton width={200}/>,}
        ],
    }
};

export class Product extends React.Component {
    constructor() {
        super();

        this.state = {
            ...SkeletonProduct(),
            loaded: false,
            openOffers: false,
        }
    }

    calculateMinPrice(offers) {
        const uniquePrices = [...new Set(offers.map(obj => obj.price))];

        if (uniquePrices.length === 0) {
            return null
        }

        if (uniquePrices.length > 1) {
            return 'от ' + Math.min(...uniquePrices)
        }

        return Math.min(uniquePrices);
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        const path = this.props.match.params.slug;

        this.context.context.processor.catalog.product(path).then(res => {
            const shopsAvailable = res.offers.filter(el => {
                return el.shop.address != null && el.shop.address !== '' && el.balance >= 1
            }).length;

            this.setState({
                price: this.calculateMinPrice(res.offers),
                product: res.product,
                offers: res.offers,
                breadcrumbs: res.breadcrumbs,
                shopsAvailable: shopsAvailable,
                loaded: true,
            })
        }).catch(err => {
            window.location.replace('/404')
        })
    }

    componentDidUpdate(prevProps) {
        const path = this.props.match.params[0];
        const prevPath = prevProps.match.params[0];

        if (path !== prevPath) {
            this.setState({...SkeletonProduct(), loaded: false})

            this.componentDidMount()
        }
    }

    openOffers () {
        this.setState({openOffers: true});
        setTimeout(() => {this.setState({openOffers: false})}, 50)

        const rect = document.querySelector('.product-switch').getBoundingClientRect();

        window.scrollTo({
            top: rect.top,
            behavior: 'smooth' // smooth scroll
        });

    }

    render() {
        return <div className={"product-wrapper"}>
            <div className="product-container container">
                <div className="product-breadcrumbs">
                    <Breadcrumbs prefix={'/catalog/'} parts={this.state.breadcrumbs}/>
                </div>
                <div className="product-main">
                    <div className="product-info__heading mobile">
                        <span className="product-info__code">Артикул: {this.state.product.code}</span>
                        <h1 className="product-info__title">{this.state.product.title}</h1>
                    </div>
                    <div className="product-gallery">
                        <ProductImagesSlider images={this.state.product.images}/>
                    </div>
                    <div className="product-info">
                        <div className="product-info__heading desktop">
                            <span className="product-info__code">Артикул: {this.state.product.code}</span>
                            <h1 className="product-info__title">{this.state.product.title}</h1>
                        </div>
                        <div className="product-info__available">
                            {this.state.loaded ? <>
                                <span>В наличии </span>
                                <span className="product-info__available--shops" onClick={() => {this.openOffers()}}>
                                    в {pluralize(this.state.shopsAvailable, ['магазине', 'магазинах', 'магазинах'])}
                                </span>
                            </> : <Skeleton width={200}/>}
                        </div>
                        {this.state.price ?
                            <div className="product-info__purchase">
                                <div className="product-info__price">
                                    <span className="value">{this.state.price}</span>
                                    <span className="currency">₽</span>
                                </div>
                                <div className="product-info__button">
                                    {this.state.loaded ? <ProductBuyButton product={this.state.product}/> :
                                        <Button className={"btn-green"} text={<LoaderSmall/>}/>}
                                </div>
                            </div> : null}
                    </div>
                </div>
                {this.context.context.settings.isMobile
                    ? <ProductSwitchMobile description={this.state.product.description} offers={this.state.offers}  openOffers={this.state.openOffers}/>
                    : <ProductSwitch description={this.state.product.description} offers={this.state.offers} openOffers={this.state.openOffers}/>
                }
            </div>
        </div>
    }
}

const ProductBuyButton = ({product}) => {
    const {context} = useContext(AppContext);

    const cartProduct = context.cart.state.products[product.id];

    const addProduct = (id) => {
        context.processor.cart.addProduct(id).then(res => {
            context.cart.dispatch({type: 'setCart', payload: res.cart})
        })
    }

    const removeProduct = (id) => {
        context.processor.cart.removeProduct(id).then(res => {
            context.cart.dispatch({type: 'setCart', payload: res.cart})
        })
    }

    return cartProduct
        ? <Counter product={cartProduct} plusCallback={addProduct} minusCallback={removeProduct}/>
        : <Button onClick={() => {
            addProduct(product.id)
        }} className={"btn-green"} text={"Добавить в корзину"}/>
};

const ProductSwitch = ({description, offers, openOffers}) => {
    const [active, setActive] = useState(0);

    useEffect(() => {
        setActive(description !== '' && description ? 0 : 1)
    }, [description])

    useEffect(() => {
        if (openOffers === true) {
            setActive(2);
        }
    }, [openOffers])


    return <div className="product-switch">
        <div className="product-switch__tabs">
            {description !== '' && description !== null ?
                <div onClick={() => {
                    setActive(0)
                }} className={`product-switch__tab ${active === 0 ? 'active' : ''}`}>
                    <div className="product-switch__tab-icon">
                        <DescriptionProductIcon/>
                    </div>
                    <div className="product-switch__tab-label">Описание</div>
                </div> : null}
            <div onClick={() => {
                setActive(1)
            }} className={`product-switch__tab ${active === 1 ? 'active' : ''}`}>
                <div className="product-switch__tab-icon">
                    <TestimonialsProductIcon/>
                </div>
                <div className="product-switch__tab-label">Отзывы</div>
            </div>
            <div onClick={() => {
                setActive(2)
            }} className={`product-switch__tab ${active === 2 ? 'active' : ''}`}>
                <div className="product-switch__tab-icon">
                    <AvailabilityProductIcon/>
                </div>
                <div className="product-switch__tab-label">Наличие в магазинах</div>
            </div>
        </div>
        <div className="product-switch__body">
            {description !== '' && description !== null ?
                <div className={`product-switch__content product-switch__description ${active === 0 ? 'active' : ''}`}>
                    <div className="product-switch__content-title">Описание</div>
                    <div className="product-switch__content-body">
                        {description}
                    </div>
                </div> : null}
            <div className={`product-switch__content product-switch__testimonials ${active === 1 ? 'active' : ''}`}>
                <div className="product-switch__content-title">Отзывов ещё нет, будьте первыми!</div>
                <div className="product-switch__content-body">
                    <div className="product-swith__testimonials-subtitle">Расскажите о преимуществах и
                        недостатках товара. Ваш отзыв поможет другим покупателям сделать выбор.
                    </div>
                    <div className="product-swith__testimonials-info">Оставить отзыв можно после покупки
                        товара
                    </div>
                </div>
            </div>
            <div className={`product-switch__content product-switch__availability ${active === 2 ? 'active' : ''}`}>
                <div className="product-switch__content-title">Наличие в магазинах</div>
                <div className="product-switch__content-body">
                    {offers.map(el => {
                        if (el.shop.address != null && el.shop.address !== '' && el.balance >= 1) {
                            return <div className="product-switch__availability-offer">
                                <div className="product-switch__availability-offer--address"><AvailableIcon
                                    balance={el.balance}/> {el.shop.address}</div>
                                <div className="product-switch__availability-offer--price">{el.price} ₽</div>
                            </div>
                        }
                    })}
                </div>
            </div>
        </div>
    </div>
}

const ProductSwitchMobile = ({description, offers, openOffers}) => {
    const [active, setActive] = useState(0);


    useEffect(() => {
        setActive(description !== '' && description ? 0 : 1)
    }, [description]);

    useEffect(() => {
        if (openOffers === true) {
            setActive(2);
        }
    }, [openOffers])

    return <div className="product-switch">
        {description !== '' && description !== null ?
            <div onClick={() => {
                setActive(0)
            }} className={`product-switch__tab ${active === 0 ? 'active' : ''}`}>
                <div className="product-switch__tab-header">
                    <div className="product-switch__tab-icon">
                        <DescriptionProductIcon/>
                    </div>
                    <div className="product-switch__tab-label">Описание</div>
                    <div className="product-switch__tab-caret"><img src={caret} alt=""/></div>
                </div>
                <div className="product-switch__tab-content">

                    <div className={`product-switch__content`}>
                        <div className="product-switch__content-title">Описание</div>
                        <div className="product-switch__content-body">
                            {description}
                        </div>
                    </div>
                </div>
            </div> : null}
        <div onClick={() => {
            setActive(1)
        }} className={`product-switch__tab ${active === 1 ? 'active' : ''}`}>
            <div className="product-switch__tab-header">
                <div className="product-switch__tab-icon">
                    <TestimonialsProductIcon/>
                </div>
                <div className="product-switch__tab-label">Отзывы</div>
                <div className="product-switch__tab-caret"><img src={caret} alt=""/></div>
            </div>
            <div className="product-switch__tab-content">
                <div className={`product-switch__content product-switch__testimonials`}>
                    <div className="product-switch__content-title">Отзывов ещё нет, будьте первыми!</div>
                    <div className="product-switch__content-body">
                        <div className="product-switch__testimonials-subtitle">Расскажите о преимуществах и
                            недостатках товара. Ваш отзыв поможет другим покупателям сделать выбор.
                        </div>
                        <div className="product-switch__testimonials-info">Оставить отзыв можно после покупки
                            товара
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div onClick={() => {
            setActive(2)
        }} className={`product-switch__tab ${active === 2 ? 'active' : ''}`}>
            <div className="product-switch__tab-header">
                <div className="product-switch__tab-icon">
                    <AvailabilityProductIcon/>
                </div>
                <div className="product-switch__tab-label">Наличие в магазинах</div>
                <div className="product-switch__tab-caret"><img src={caret} alt=""/></div>
            </div>
            <div className="product-switch__tab-content">
                <div className={`product-switch__content product-switch__availability`}>
                    <div className="product-switch__content-title">Наличие в магазинах</div>
                    <div className="product-switch__content-body">
                        {offers.map(el => {
                            if (el.shop.address != null && el.shop.address !== '' && el.balance >= 1) {
                                return <div className="product-switch__availability-offer">
                                    <div className="product-switch__availability-offer--address"><AvailableIcon
                                        balance={el.balance}/> {el.shop.address}</div>
                                    <div className="product-switch__availability-offer--price">{el.price} ₽</div>
                                </div>
                            }
                        })}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const AvailableIcon = ({balance}) => {
    switch (balance) {
        case 1:
            return <div className={'product-balance product-balance--last'}>
                <div className={"product-balance-stick"}/>
                <div className={"product-balance-stick"}/>
                <div className={"product-balance-stick"}/>
            </div>;
        case 2:
        case 3:
            return <div className={'product-balance product-balance--few'}>
                <div className={"product-balance-stick"}/>
                <div className={"product-balance-stick"}/>
                <div className={"product-balance-stick"}/>
            </div>;
        default:
            return <div className={'product-balance product-balance--many'}>
                <div className={"product-balance-stick"}/>
                <div className={"product-balance-stick"}/>
                <div className={"product-balance-stick"}/>
            </div>;
    }
}

Product.contextType = AppContext;