import React from "react";
import {Link} from 'react-router-dom'
import {AppContext} from "../../../store/context";

import clubhand from '../../../static/img/icons/club-hand.svg'
import clubphoto from '../../../static/img/icons/club-photo.svg'

import {Breadcrumbs} from "../../../components/ui/Breadcrumbs";
import {STATIC_HOST} from "../../../const";

export class ClubPage extends React.Component {
    constructor() {
        super();

        this.state = {
            block: 'advice',
            advices: [],
            galleries: [],
            loaded: false
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        this.context.context.processor.content.club().then(res => {
            const advices = [];
            const galleries = [];

            res.articles.map(el => {
                if (el.type == 1) {
                    advices.push(el)
                }
                if (el.type == 2) {
                    galleries.push(el)
                }
            });

            this.setState({advices: advices, galleries: galleries, loaded: true});
        }).catch(err => {
            this.setState({loaded: true});
        })
    }

    render() {
        return <div className="club-wrapper">
            <div className="club-container container">
                <div className="club-breadcrumbs">
                    <Breadcrumbs parts={[{title: 'Главная', slug: '/'}, {title: 'Клуб "Универсадоводы"'}]}/>
                </div>
                <div className="club-image">
                    <div className="club-image__title">Клуб «Универсадоводы»</div>
                    <div className="club-image__text">
                        <div>Что нужно посадить и когда?</div>
                        <div>Как ухаживать за своим садом?</div>
                        <div>Какие инструменты выбрать для работы?</div>
                        <div>Найдите ответы на интересующие вопросы и вдохновитесь с "Универсадом"!</div>
                    </div>
                </div>
                <div className="club-selector">
                    <div className={`club-selector__button ${this.state.block === 'advice' ? 'active' : ''}`}
                         onClick={() => {
                             this.setState({block: 'advice'})
                         }}>
                        <div className="club-selector__button--icon">
                            <img src={clubhand}/>
                        </div>
                        <div className="club-selector__button--text">
                            Советы Универсадовода
                        </div>
                    </div>
                    <div className={`club-selector__button ${this.state.block === 'galleries' ? 'active' : ''}`}
                         onClick={() => {
                             this.setState({block: 'galleries'})
                         }}>
                        <div className="club-selector__button--icon">
                            <img src={clubphoto}/>
                        </div>
                        <div className="club-selector__button--text">
                            Фотогалереи
                        </div>
                    </div>
                </div>
                <div className="club-block">
                    {this.state.block === 'advice'
                        ? <AdviceBlock advices={this.state.advices}/>
                        : <GalleryBlock galleries={this.state.galleries}/>}
                </div>
            </div>
        </div>
    }
}

const AdviceBlock = ({advices}) => {
    return advices.map(el => {
        return <Link to={`/club/${el.slug}`} className="club-advice">
            <div>{el.title}</div>
        </Link>
    });
};

const GalleryBlock = ({galleries}) => {
    return <div className="club-galleries">
        {galleries.map(el => {
            return <Link to={`/club/${el.slug}`} className="club-gallery">
                <div className="club-gallery__image" style={{backgroundImage: `url(${STATIC_HOST + 'articles/' + el.image})`}}/>
                <div className="club-gallery__text">
                    <div>{el.createdAt}</div>
                    <div>{el.title}</div>
                    </div>
            </Link>
        })}
    </div>

};


ClubPage.contextType = AppContext;