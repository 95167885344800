import {Link} from 'react-router-dom'
import {STATIC_HOST} from "../../const";
import {Loader} from "../Loader";
import {AngleRight} from "../ui/Icons";
import placeholder from '../../static/img/photo-placeholder.png'


export const ActionCard = ({item}) => {
    return <div className="action-card">
        {typeof item.title !== 'object'
            ? <div className="action-card__image"
                   style={{backgroundImage: `url(${item.image ? STATIC_HOST + 'articles/' + item.image : placeholder})`}}>
                <div className="action-card__date">
                    с {item.startAt}
                    {item.endAt ? ' по ' + item.endAt : ''}
                </div>
            </div>
            : <div className="action-card__image">
                <Loader/>
            </div>
        }
        <div className="action-card__content">
            <h4 className={'action-card__title'}><Link to={`/actions/${item.slug}`}>{item.title}</Link></h4>
            <div className="action-card__preview">{item.preview}</div>
            <div className="action-card__more">
                <span className="action-card__more--text"><Link to={`/actions/${item.slug}`}>Подробнее</Link></span>
                <span className="action-card__more--icon"><AngleRight/></span>
            </div>
        </div>
    </div>
};