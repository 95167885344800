import Skeleton from 'react-loading-skeleton'

import cm1 from './../static/img/icons/catalog-menu-1.svg'
import cm2 from './../static/img/icons/catalog-menu-2.svg'
import cm3 from './../static/img/icons/catalog-menu-3.svg'
import cm4 from './../static/img/icons/catalog-menu-4.svg'
import cm5 from './../static/img/icons/catalog-menu-5.svg'
import cm6 from './../static/img/icons/catalog-menu-6.svg'
import cm7 from './../static/img/icons/catalog-menu-7.svg'


export const CatalogReducer = (state, action) => {
    switch (action.type) {
        case 'setCategories':
            return {...state, categories: action.payload};
        case 'setNav':
            return {...state, navigation: action.payload};
    }
};
export const CatalogInitialState = {
    navigation: [
        {
            label: <Skeleton width={100}/>,
            link: '#',
            internal: true
        },
        {
            label: <Skeleton width={100}/>,
            link: '#',
            internal: true
        },
        {
            label: <Skeleton width={150}/>,
            link: '#',
            internal: true
        },
        {
            label: <Skeleton width={150}/>,
            link: '#',
            internal: true
        },
        {
            label: <Skeleton width={150}/>,
            link: '#',
            internal: true
        },
        {
            label: <Skeleton width={100}/>,
            link: '#',
            internal: true
        },
        {
            label: <Skeleton width={100}/>,
            link: '#',
            internal: true
        },
    ],
    categories: [
        {
            title: <Skeleton width={200}/>,
            icon: cm1,
            sort: 0,
            category: null
        },
        {
            title: <Skeleton width={200}/>,
            icon: cm2,
            sort: 0,
            category: null
        },
        {
            title: <Skeleton width={180}/>,
            icon: cm3,
            sort: 0,
            category: null
        },
        {
            title: <Skeleton width={200}/>,
            icon: cm4,
            sort: 0,
            category: null
        },
        {
            title: <Skeleton width={170}/>,
            icon: cm5,
            sort: 0,
            category: null
        },
        {
            title: <Skeleton width={200}/>,
            icon: cm6,
            sort: 0,
            category: null
        },
        {
            title: <Skeleton width={200}/>,
            icon: cm7,
            sort: 0,
            category: null
        },
    ]
};

export const UserReducer = (state, action) => {
    switch (action.type) {
        case 'initialize':
            return {...action.payload};
        case 'auth':
            return {...state, customer: action.payload};
        case 'dontAsk':
            return {...state, askCity: false};
    }
};
export const UserInitialState = {
    customer: {
        phone: null,
        name: null,
        email: null,
    },
    loaded: false,
    askCity: false,
    cities: [],
    legal: null,
    city: {
        id: 949,
        title: <Skeleton width={100}/>
    },
}

export const ModalReducer = (state, action) => {
    switch (action.type) {
        case 'openModal':
            return {...action.payload, open: true};
        case 'closeModal':
            return {open: false, type: null};
    }
};
export const ModalInitialState = {
    open: false,
    type: null,
    params: {}
};


export const CartReducer = (state, action) => {
    switch (action.type) {
        case 'setCart':
            return {...action.payload};
    }
};
export const CartInitialState = {
    count: 0,
    products: {},
}
