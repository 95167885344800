import {Base} from "./base.api";
import {API_HOST} from "../const";

const BASE_URL = `${API_HOST}/user`;

export class User extends Base {
    load() {
        return this.fetchJSON(`${BASE_URL}/`).then(response => {
            return response.data
        })
    }

    update(data) {
        return this.fetchJSON(`${BASE_URL}/`, {
            method: 'post',
            body: JSON.stringify({name: data.name, card: data.card, email: data.email})
        }).then(response => {
            return response.data
        })
    }

    selectCity(id) {
        return this.fetchJSON(`${BASE_URL}/city/${id}`).then(response => {
            return response.data
        })
    }

    order(number) {
        return this.fetchJSON(`${BASE_URL}/order?number=${number}`).then(response => {
            return response.data
        })
    }

    sendCode(phone) {
        return this.fetchJSON(`${BASE_URL}/auth/send`, {
            method: 'post',
            body: JSON.stringify({phone: phone})
        }).then(response => {
            return response.data
        })
    }

    validateCode(phone, code) {
        return this.fetchJSON(`${BASE_URL}/auth/validate`, {
            method: 'post',
            body: JSON.stringify({phone: phone, code: code})
        }).then(response => {
            return response.data
        })
    }
}